@import "styles/resources/mixins.scss";

.upsell-modal {
  font-size: 0.8rem;

  @include media(">phone") {
    font-size: 1rem;
  }

  &__info {
    display: flex;

    &__storage {
      display: flex;
      justify-content: center;
    }

    &__storage-doughnut {
      width: 300px;
    }

    &__total-storage {
      margin-top: 8px;
      text-align: center;

      @include media(">tablet") {
        text-align: right;
      }
    }
  }

  &__feature {
    margin-top: 0;
  }

  &__required-plan-wrapper {
    display: flex;
    align-items: center;

    p {
      margin: 0;
    }
  }

  &__required-plan-badge {
    margin-left: 8px;
  }
  
  &__plan-period-picker {
    margin-top: 26px
  }

  &__plan-list {
    margin-top: 16px;
  }

  &__plan {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border: 1px solid #80808085;
    border-radius: 8px;
    margin-top: 8px;

    &__main-info {
      display: flex;
      align-items: center;
    }

    &__title {
      font-size: 1rem;
      font-weight: bold;
      margin-right: 12px;

      @include media(">phone") {
        font-size: 1.3rem;
      }
    }

    &__price {
      font-size: 0.6rem;
      color: #4a4848;

      @include media(">phone") {
        font-size: 0.8rem;
      }
    }

    &__storage-available {
      font-size: 0.6rem;
      color: #4a4848;
      margin-left: 12px;

      @include media(">phone") {
        font-size: 0.8rem;
      }

      &::before {
        content: "|";
        margin-right: 8px;
      }
    }
  }

  &__account-settings-link {
    display: block;
    margin-top: 16px;
  }
}
