@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.professionals {
  &__section {
    padding: 40px 0;

    &--side-margin {
      padding: 40px 0;

      @include media(">tablet") {
        width: 100%;
        padding: 40px 10%;
      }
    }

    &--primary {
      background-color: $background__primary-color;
    }

    &--secondary {
      background-color: $background__secondary-color;
    }
  }
}