@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.tags-container {
  padding: 20px;
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: left;
  overflow: auto;

  @include media(">tablet") {
    justify-content: center;
  }

  &__link {
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s;

    @media (hover: hover) {
      &:hover {
        background-color: $primary-color;
      }
    }

    &--active {
      background-color: $primary-color;
    }
  }
}
