:root {
  --background-size: 24px;
  --background-color: aliceblue;
}

.icon {
  &__char {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 24px;
    width: 24px;
    height: 24px;

    &--background {
      width: var(--background-size);
      height: var(--background-size);
      flex-shrink: 0;
      background-color: var(--background-color);
      border-radius: 50%;
    }
  }
}