@import "styles/theme/colors.scss";
@import "styles/resources/mixins.scss";

.pack-image-picker {
  display: flex;
  overflow: scroll;
  width: 100%;

  &__image-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;

    @include media(">small-tablet") {
      height: 200px;
    }

    @include media(">tablet") {
      height: 220px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    cursor: pointer;
    box-sizing: border-box;

    &--selected {
      border: 2px solid $secondary-color;
    }
  }

  &__selected-image {
    position: absolute;
    font-size: 50px !important;
    fill: $secondary-color !important;
  }

  &__actions {
    display: flex;
    width: 100%;
    justify-content: space-between;

    margin-top: 30px !important;

    @include media(">tablet") {
      justify-content: flex-end;
    }

    &__button {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}