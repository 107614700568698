@import "styles/theme/box-styles.scss";
@import "styles/theme/colors.scss";
@import "styles/resources/mixins.scss";

.category-search-result-item {
  display: flex;
  background-color: $white;
  padding: 15px 30px;
  border-radius: 10px;
  box-shadow: $box-shadow-whole;

  &--gallery {
    flex-direction: column;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    justify-content: center;
    min-width: 0; // needed so that content doesn't overflow parent

    &--gallery {
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
    }  

    &__title {
      margin: 0;
      margin-bottom: 10px;
      text-align: center;
      font-size: 1rem;
    }
  }
}