.new-feature-tooltip-wrapper {
  position: relative;
}

.new-feature-tooltip {
  position: absolute;
  padding: 12px;
  background: #f2f4f5;
  z-index: 10;
  border-radius: 12px;
  margin-top: 8px;
}
