@import "styles/resources/mixins.scss";

.start-ease {
  &__title {
    text-align: center;
    font-size: 2rem;
    font-weight: normal;
    padding: 0 10px;

    @include media(">desktop") {
      padding: 0;
      font-size: 3rem;
    }
  }

  &__grid {
    padding: 0;

    @include media(">desktop") {
      padding: 0 140px;
    }
  }

  &__item {
    text-align: center;
    padding: 20px;

    @include media(">desktop") {
      text-align: left;
    }

    &__icon {
      font-size: 4rem !important;
    }

    &__sub-title {
      white-space: pre-line;
    }
  }
}
