.appointment-quick-view {
  padding: 20px;

  &__title {
    margin: 8px 0;
  }

  &__user {
    margin-top: 24px;
    margin-left: 6px;
  }

  &__icon-info {
    margin-top: 8px;
    font-size: 0.9rem;

    svg {
      width: 36px;
    }
  }

  &__action-buttons {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;

    &__button {
      &:not(:last-child) {
        margin-right: 15px;
      }

      &--left {
        margin-right: auto !important;
      }
    }
  }
}