$side-margin-size: 8px;

:root {
  --gap-size: 8px;
}

.gallery-masonry-grid {
  display: flex;
  margin-left: calc(#{$side-margin-size} - #{var(--gap-size)});
  margin-right: $side-margin-size;
  width: auto;

  &__column {
    padding-left: var(--gap-size);
    background-clip: padding-box;
    
    /* Style your items */
    & > div {
      margin-bottom: var(--gap-size);
    }
  }
}

.gallery-masonry-layout {
  display: flex;
  width: 100%;

  &__column {
    flex-grow: 1;
    height: 100%;
    margin: 0px calc(var(--gap-size) / 2);

    &--first {
      margin: 0px calc(var(--gap-size) / 2) 0px var(--gap-size);
    }

    &--last {
      margin: 0px var(--gap-size) 0px calc(var(--gap-size) / 2);
    }

    &__item {
      position: relative;
      margin-bottom: var(--gap-size);

      &--wide {
        width: calc(100% * 2 + #{var(--gap-size)});
      }

      &--show-indexes {
        &:before {
          content: attr(data-index);
          position: absolute;
          bottom: 4px;
          right: 4px;
          font-size: 8px;
          background: white;
          z-index: 9;
        }
      }
    }
  }
}
