@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";
@import "styles/theme/box-styles.scss";

.post {
  &.skeleton {
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
    width: 100%;
    transition: transform 0.2s;
    background-color: white;

    @include media(">desktop") {
      flex-direction: row;
    }

    .metadata {
      @include media(">desktop") {
        width: 25%;
        height: 80px;
      }
      width: 20%;
      border-radius: 10px;
      background-color: #ddd;
    }

    .index {
      @include media(">desktop") {
        width: 25%;
        height: 220px;
      }
      width: 20%;
      border-radius: 10px;
      background-color: #ddd;
    }

    .content {
      @include media(">desktop") {
        width: 75%;
      }

      .img {
        border-radius: 10px;
        width: 100%;
        height: 225px;
        background-color: #ddd;
      }

      .text {
        height: 100px;
        width: 100%;
        background-color: #ddd;
        margin-top: 10px;
        border-radius: 10px;
      }
    }

    animation: skeleton-loading 1.5s infinite ease-in-out;

    @keyframes skeleton-loading {
      0%,
      100% {
        opacity: 0.6;
      }
      50% {
        opacity: 1;
      }
    }
  }
}
