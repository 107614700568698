@import "styles/theme/colors.scss";
@import "styles/theme/box-styles.scss";
@import "styles/resources/mixins.scss";

$SEARCH_BAR_CONTENT_ANIMATION_DURATION: 250ms;

.search-bar-with-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__input {
    &--open {
      border-radius: 10px 10px 0 0;
      box-shadow: inset 0 -2px 0 #dcdbdb; // inner bottom border
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    min-height: 200px;
    max-height: 75vh;
    transform: scaleY(0);
    transform-origin: 50% 0;
    top: 55px;
    background-color: $background__primary-color;
    transition: all $SEARCH_BAR_CONTENT_ANIMATION_DURATION ease;
    opacity: 0;
    z-index: 11;
    overflow: scroll;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: $default-box-shadow;
    border-radius: 0 0 10px 10px;

    &--open {
      transform: scaleY(1);
      opacity: 1;
    }

    @include media(">tablet") {
      padding: 20px 45px;
      top: 65px;
      max-height: calc(100vh - 150px);
    }

    ol {
      padding: 0;
    }

    li {
      list-style: none;
    }
  }
}