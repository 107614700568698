@import "styles/resources/mixins.scss";

.image-input-form-step {
  position: relative;
  display: flex;
  width: calc(100% - 40px);
  height: 100%;
  margin: 40px 0;
  text-align: center;

  @include media(">small-tablet") {
    width: calc(100% - 80px);
    text-align: left;
    margin: 0;
    margin-left: 80px;
  }

  &__title {
    position: relative;
    z-index: 1;
    margin-top: 0;
  }

  &__content {
    &--left {
      width: 100%;
      flex-grow: 1;
      margin-right: 0;
      
      @include media(">small-tablet") {
        min-width: 50%;
        max-width: 600px;
        padding: 40px 10px 0 0;
        margin-right: 20px;
      }
    }

    &--right {
      display: none;
      position: relative;
      width: 50%;

      @include media(">small-tablet") {
        display: block;
      }
    }
  }

  &__form {
    align-items: center;
    display: flex;
    flex-direction: column;

    @include media(">small-tablet") {
      align-items: flex-start;
    }
  }

  &__form-item {
    position: relative;
    margin-top: 50px;
    width: 100%;
    text-align: left;

    &__text-field {
      width: 100%;

      @include media(">small-tablet") {
        max-width: 600px;
      }
    }
  }

  &__form-item-label {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__actions {
    display: flex;
    margin-top: 120px;
    margin-bottom: 40px;
    width: 100%;

    > *:not(:first-child) {
      margin-left: 8px;
    }
  }

  &__form-submit {
    width: 100%;

    &:nth-child(2) {
      margin-right: auto;
    }

    &:last-child {
      margin-right: 0;
    }

    button {
      width: 100%;
    }

    &--back {
      display: none;
    }

    &--skip {
      margin: 0;
    }

    @include media(">small-tablet") {
      width: auto;

      &--back {
        display: initial;
      }
    }
  }

  &__image {
    position: absolute;
    display: none;
    object-fit: cover;
    max-width: 1200px;

    @include media(">small-tablet") {
      display: initial;
      margin-top: -82px;
      min-height: 100vh;
      height: calc(100% + 82px);
      width: 100%;
      top: 0;
      right: 0;
      border-radius: 30px 0 0 30px;
    }
  }
}
