@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

:root {
  --modal-width: 80%;
}

$mobile-bottom-safe-distance: 40px;

.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    opacity: 0;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);

    &--open {
      opacity: 1;

      @include media(">tablet") {
        transition-delay: 0s;
      }
    }

    &--hidden {
      transition-delay: 0s;
      opacity: 0;
    }
  }
}

.modal {
  position: fixed; // this must be fixed so that when mobile bottom toolbar is IOS is open, the content is shifted upwards automatically
  width: 100%;
  bottom: 0;
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: white;
  opacity: 0;
  transform: translateY(100vh);

  &--open {
    opacity: 1;
    transform: translateY(0);
  }

  &--hidden {
    opacity: 0;
    transform: translateY(100vh);
  }

  @include media(">tablet") {
    position: relative;
    width: var(--modal-width);
    border-radius: 12px;
    height: auto;
    transform: translateY(0);
    align-items: center;
  }

  &__header {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 38px;
    border-bottom: 1px solid rgb(235, 235, 235);
    z-index: 10;
    background-color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    &--danger {
      border-color: $danger-color;
    }

    &__close-btn {
      position: absolute !important;
      right: 16px;
      top: 6px;
    }
  }

  &__content {
    padding: 59px 20px $mobile-bottom-safe-distance 20px;
    box-sizing: border-box;
    width: 100%;
    max-height: 97vh;
    overflow-y: scroll;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    &--overflow-visible {
      overflow: visible;
    }

    &--min-width {
      width: auto;
    }

    &--no-padding {
      padding: 0;
      padding-top: 39px; // avoding header overlap
      padding-bottom: $mobile-bottom-safe-distance; // add distance to bottom in mobile devices
    }

    @include media(">small-tablet") {
      padding-bottom: 20px;

      &--no-padding {
        padding-bottom: 0;
      }
    }

    @include media(">tablet") {
      max-height: 90vh;
      border-radius: 12px;
    }
  }

  &__with-sections {
    background-color: rgb(242, 244, 245);

    &__header {
      background-color: rgb(242, 244, 245);      
    }
  }
}

.simple-modal {
  &__content {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    justify-content: space-around;
    align-items: baseline;

    @include media(">tablet") {
      min-height: 0;
    }
  }

  &__title {
    margin-bottom: 22px;
  }

  &__message {
    margin-bottom: 20px;
    white-space: pre-line;
  }

  &__actions {
    margin-left: auto;

    :last-child {
      margin-left: 20px;
    }
  }
}

.modal-section {
  margin-top: 24px;
  background: white;
  padding: 24px;
  border-radius: 8px;

  &--no-margin {
    margin-top: 0;
  }

  &--last {
    margin-bottom: 54px;

    @include media(">tablet") {
      margin-bottom: 72px;
    }
  }

  &__title {
    margin-bottom: 10px;
  }

  &__sub-title {
    margin-bottom: 12px;
  }
}

.modal-footer {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  overflow: hidden;
  margin-top: 12px;

  &__action-buttons {
    display: flex;
    gap: 10px;
    justify-content: space-around;
    align-items: center;
    background: white;
    padding: 12px;
    width: 100%;
    margin: 0 19px;
    margin-bottom: 12px;
    border-radius: 8px;
    border: 1px solid #f2f4f5;
    box-shadow: 0px 10px 0px 3px #f2f4f5;

    @include media(">tablet") {
      justify-content: flex-end;
    }
  }
}
