@import "styles/theme/colors.scss";
@import "styles/resources/mixins.scss";

.register-journey {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  @include media(">small-tablet") {
    align-items: flex-start;
  }

  &__logo {
    width: 100px;
    margin-top: 30px;
    margin-left: 0;

    @include media(">small-tablet") {
      margin-left: 60px;
    }

    img {
      width: 100%;
    }
  }

  &__go-back-button {
    position: absolute !important;
    left: 40px;
    top: 32px;
  }
}