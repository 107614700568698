@import "styles/theme/colors.scss";
@import "styles/resources/mixins.scss";

.pack-input {
  &__title {
    width: 100%;
      
    @include media(">tablet") {
      width: 40%;
    }
  }

  &__section {
    margin-top: 24px;
    background: white;
    padding: 24px;
    border-radius: 8px;

    &--no-margin {
      margin-top: 0;
    }

    &--last {
      margin-bottom: 54px;

      @include media(">tablet") {
        margin-bottom: 68px;
      }
    }

    &__title {
      margin-bottom: 10px;
    }

    &__sub-title {
      margin-bottom: 12px;
    }
  }

  &__visibility-checkbox {
    margin-top: 20px;
  }

  &__categories {
    margin-top: 20px;
    width: 100%;
  }

  &__footer {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    overflow: hidden;

    &__action-buttons {
      display: flex;
      gap: 10px;
      justify-content: space-around;
      align-items: center;
      background: white;
      padding: 12px;
      width: 100%;
      margin: 0 19px;
      margin-bottom: 12px;
      border-radius: 8px;
      border: 1px solid #f2f4f5;
      box-shadow: 0px 10px 0px 3px #f2f4f5;

      @include media(">tablet") {
        justify-content: flex-end;
      }
    }
  }
}