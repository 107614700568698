@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

@mixin picture {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin title {
  color: inherit;
  
  // font
  font-size: 1.5em;
  line-height: 1em;
  letter-spacing: .375em;
  text-transform: inherit;
  font-weight: lighter;
}

@mixin title-tablet-and-up {
  font-size: 2em;
  line-height: 1em;
}

@mixin professional-name {
  text-align: center;
  color: inherit;
  width: fit-content;

  // font
  font-size: 1em;
  letter-spacing: .375em;
  text-transform: inherit;
  font-weight: lighter;
}

@mixin professional-name-tablet-and-up {
  font-size: 1.5em;
}

@mixin arrow-down {
  color: inherit;

  button {
    transform: rotate(90deg);
    font-size: inherit;
  }

  svg {
    font-size: 2.5em;
  }
}

@mixin base-font-size {
  font-size: 2vw;

  @include media(">tablet") {
    font-size: 1vw;
  }

  @include media(">bigScreen") {
    font-size: 0.8vw;
  }
}

@mixin base-font-size-preview {
  font-size: 1vw;
  line-height: 8px;

  @include media(">tablet") {
    font-size: 0.6vw;
  }

  @include media(">bigScreen") {
    font-size: 0.4vw;
  }
}

@mixin base-font-size-preview-mobile {
  font-size: 2vw;
  line-height: 8px;

  @include media(">tablet") {
    font-size: 0.6vw;
  }

  @include media(">bigScreen") {
    font-size: 0.4vw;
  }
}

:root {
  --cover-color: black;
  --cover-background-color: white;
  --cover-text-transform: uppercase;
  --cover-border-radius: 10px;
}

.gallery-cover-wrapper {
  @include base-font-size;

  color: var(--cover-color);
  border-color: var(--cover-color);
  text-transform: var(--cover-text-transform);
  background-color: var(--cover-background-color);
  height: 100%;

  &--preview {
    @include base-font-size-preview;
  }

  &--mobile-preview {
    @include base-font-size-preview-mobile;
  }
}

.gallery-cover {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(106, 106, 106, 0.40) 0%, rgba(128, 128, 128, 0.30) 4%, rgba(128,128,128,0) 70%);
  }

  &__border {
    position: absolute;
    border: 1px solid;
    border-color: inherit;
    width: 98%;
    height: 98%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__content {
    position: absolute;
    width: 90%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    gap: 24px; // mobile device or mobile preview (in mobile or desktop device)

    &--tablet-and-up {
      gap: 4px; // desktop preview in mobile device

      // desktop or desktop preview in desktop device
      @include media(">tablet") {
        gap: 16px;
      }
    }
  }

  &__title {
    @include title;

    &--tablet-and-up {
      @include title-tablet-and-up;
    }

    justify-content: center;
  }

  &__professional-name {
    @include professional-name;
    
    &--tablet-and-up {
      @include professional-name-tablet-and-up;
    }
  }

  &__arrow-down {
    @include arrow-down;
  }
}

.gallery-cover-ch {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;

  &__picture-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80%;

    &--tablet-and-up {
      max-height: 60%; // desktop preview in mobile device

      // desktop or desktop preview in desktop device
      @include media(">tablet") {
        max-height: 80%;
      }
    }
  }

  &__picture {
    @include picture;

    width: 75%;
    height: 100%;
    max-height: 90%;
  }

  &__image {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain !important;
    border-radius: var(--cover-border-radius);
  }

  &__content {
    position: relative;
    display: flex;
    width: 94%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 24px; // mobile device or mobile preview (in mobile or desktop device)

    &--tablet-and-up {
      gap: 4px; // desktop preview in mobile device

      // desktop or desktop preview in desktop device
      @include media(">tablet") {
        gap: 16px;
      }
    }
  }

  &__title {
    @include title;

    &--tablet-and-up {
      @include title-tablet-and-up;
    }

    justify-content: center;
    text-align: center;
  }

  &__professional-name {
    @include professional-name;

    &--tablet-and-up {
      @include professional-name-tablet-and-up;
    }
  }

  &__arrow-down {
    @include arrow-down;
  }
}

.gallery-cover-cvl {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__wrapper-image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 80%;
    object-fit: cover;
    margin: 0 32px;
    background: transparent;
  }

  &__picture {
    @include picture;

    height: 100%;
    max-height: 100%;
    max-width: 100%;
  }

  &__image {
    max-height: 100%;
    max-width: 100%;
    border-radius: var(--cover-border-radius);
    object-fit: contain !important;
  }

  &__content {
    position: relative;
    flex-grow: 1;
    height: 40%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-right: 24px;
  }

  &__title {
    @include title;

    &--tablet-and-up {
      @include title-tablet-and-up;
    }
  }

  &__professional-name-wrapper {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__professional-name {
    @include professional-name;

    &--tablet-and-up {
      @include professional-name-tablet-and-up;
    }
  }

  &__arrow-down {
    @include arrow-down;

    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
}
