@import "styles/resources/mixins.scss";

.devtools {
  &__fab {
    position: fixed !important;
    bottom: 20px;
    right: 20px;
  }

  &__ui {
    display: flex;
    flex-direction: column;

    &__signed-in-with {
      display: flex;
      align-items: center;
      margin: 20px 0;

      * {
        margin: 0;
        margin-right: 10px;
      }
    }

    &__sign-out-btn {
      width: 140px;
      margin-bottom: 20px !important;
    }

    &__sign-in-btn {
      width: 140px;
      margin-top: 20px !important;
    }

    &__menu-items {
      margin-top: 20px !important;
    }
  }

  &__storage-calculator-form {
    display: flex;
    flex-direction: column;
    width: 140px;

    &__button {
      margin: 20px 0 !important;
    }
  }

  &__search-users-form {
    display: flex;
    flex-direction: column;

    &__button {
      max-width: 140px;
      margin: 20px 0 !important;
    }
  }

  &__thumbnails-form {
    display: flex;
    flex-direction: column;

    &__button {
      margin: 20px 0 !important;
    }
  }

  &__routes {
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    &__item {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  &__breakpoints {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: black;
    color: white;
    padding: 3px;
    border-bottom-right-radius: 7px;

    &::after {
      content: 'mobile (0px)';
    }

    @include media(">small-tablet") {
      &::after {
        content: 'small-tablet (600px)';
      }
    }

    @include media(">tablet") {
      &::after {
        content: 'tablet (960px)';
      }
    }

    @include media(">desktop") {
      &::after {
        content: 'desktop (1280px)';
      }
    }

    @include media(">bigScreen") {
      &::after {
        content: 'bigScreen (1920px)';
      }
    }
  }
}