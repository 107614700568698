@import '../utils/strip-unit.scss';

//---
//  breakpoints
//---
$mobile-and-up: 0; // equivalent to xs (extra-small)
$small-tablet-and-up: 600px; // equivalent to sm (small)
$tablet-and-up: 960px; // equivalent to md (medium)
$desktop-and-up: 1280px; // equivalent to lg (large)
$large-desktop-and-up: 1920px; // equivalent to xl (extra-large)

$breakpoints: (phone: 0, small-tablet: 600px, tablet: 960px, desktop: 1280px, bigScreen: 1920px);

/*
* This breakpoints are equivalent to Material UI breakpoints
* xs, extra-small: 0px
* sm, small: 600px
* md, medium: 960px
* lg, large: 1280px
* xl, extra-large: 1920px
*/

// exported to be used in JS
:export {
  mobileAndUp: strip-unit($mobile-and-up);
  smallTabletAndUp: strip-unit($small-tablet-and-up);
  tabletAndUp: strip-unit($tablet-and-up);
  desktopAndUp: strip-unit($desktop-and-up);
  largeDesktopAndUp: strip-unit($large-desktop-and-up);
}
