@import "styles/resources/mixins.scss";

.packs {
  min-height: 600px;
  position: relative;

  &__packs {
    margin-top: 24px !important;
  }

  &__hidden-packs-title {
    margin-top: 40px;
    margin-bottom: 16px;
  }

  &__hidden-packs {
    margin-top: 20px !important;
  }

  &__input-modal {
    background-color: rgb(242, 244, 245);

    &__header {
      background-color: rgb(242, 244, 245);
    }
  }
}