@import "styles/theme/colors.scss";

$border-radius: 16px;

.pin {
  position: relative;
  font-size: 16px;
  width: 100%;

  &__header {
    position: relative;

    &__wrapper {
      border-radius: $border-radius;

      &--no-image {
        min-height: 270px;
        background-color: lightgray;
        box-shadow: 0px 5px 10px -6px #00000073;
      }
    }

    &__label {
      position: absolute;
      top: 0;
      left: 0;
      padding: 8px;
      border-top-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;

      &--pro {
        opacity: 0.7;
        background-color: red;
        color: $text__default-color;
        text-transform: uppercase;
        z-index: 1;
        font-size: 0.8rem;
      }
    }
  
    &__image {
      width: 100%;
      border-radius: $border-radius;
    }
  
    &__interaction {
      position: absolute;
      display: flex;
      width: 100%;
      height: 32px;
      bottom: 0;
      padding-bottom: 6px;
      padding-top: 2px;
      color: white;
      background: linear-gradient(0deg, black, transparent);
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;

      & > *:first-child {
        margin-left: 20px;
      }
      
      & > *:not(:last-child) {
        margin-right: 3px;
      }
      
      & > *:last-child {
        margin-right: 20px;
        font-size: 0.75rem;
        
        svg {
          margin-right: 5px;
          font-size: 1rem;
        }
      }

      &--heart {
        margin-left: auto;
        margin-right: 20px;

        &:hover {
          cursor: pointer;
          svg {
            fill: red;
          }
        }
      }

      &--fill-red {
        svg {
          fill: red;
        }
      }
    }

    div {
      cursor: pointer;
    }
  }

  &__title-wrapper-anchor {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    color: $text__default-color;
    font-size: 0.8rem;
    margin: 4px 8px;

    &--with-top-margin {
      margin: 10px 8px;
    }
  }

  &__title {
    color: inherit;
    text-align: left;
    padding: 0;
  }

  &__price {
    font-size: 0.8rem;
    white-space: nowrap;
    margin: 0;

    &__value {
      font-size: 1rem; 
    }
  }

  &__description {
    text-align: left;
    padding: 0;
    padding: 0 20px 0 8px;
    margin-top: 4px;
    text-align: justify;
  }

  &__user {
    justify-content: left;
    margin-top: 8px;
    font-size: 0.8rem;
    font-weight: bold;
  }

  &__skeleton {
    &__user {
      display: flex;
      flex-direction: column;
      position: relative;
    }
  }
}
