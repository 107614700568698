@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.footer {
  display: flex;
  min-height: 300px;
  background-color: $background__dark-color;
  flex-direction: column;
  padding: 40px 30px 80px 30px;

  @include media(">tablet") {
    padding: 100px;
  }

  &__grid {
    padding-left: 40px;
  
    @include media(">tablet") {
      padding-left: 0;
    }
  }

  &__links-group-wrapper {
    &:not(:first-child) {
      margin-top: 20px;
    }

    @include media(">small-tablet") {
      margin-top: 0;

      &:not(:first-child) {
        margin-top: 0px;
      }

      &:nth-child(n+3) {
        margin-top: 20px;
      }
    }

    @include media(">tablet") {
      margin-top: 0;

      &:nth-child(n+3) {
        margin-top: 0;
      }
    }
  }

  &__links-title {
    color: white;
    font-weight: bolder;
    text-transform: uppercase;
  }

  &__link-list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    
    &__item {
      margin-top: 20px;
  
      &:first-child {
        margin: 0;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    height: 100px;
    align-items: center;
    margin-top: 40px;

    @include media(">tablet") {
      height: auto;
      flex-direction: row;
    }

    &__logo {
      height: 30px;
      align-items: center;

      img {
        height: 55%;
      }
    }

    &__copyright {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      color: white;
    }

    &__social-networks {
      display: flex;
    }

    &__social-network {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    @include media(">tablet") {
      margin-top: 80px;
    }
  }
}