@import "styles/theme/colors.scss";
@import "styles/theme/box-styles.scss";

.postCard {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  transition: transform 0.2s;
  box-shadow: $default-box-shadow;
  background-color: white;

  &__link {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    flex-grow: 1;
    height: 100%;

    &__img {
      border-radius: 10px 10px 0 0;
      width: 100%;
      height: 75%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      margin-top: 10px;
      margin-bottom: 10px;

      &__title {
        text-align: center;
        margin: 5px;
        font-weight: 500;
      }
    }
  }

  &__tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    &__tag {
      background-color: $primary-color;
      padding: 5px 10px;
      border-radius: 15px;
      font-size: 12px;
    }
  }
}
