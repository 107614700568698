@import "styles/theme/colors.scss";

.user-circular {
  display: flex;
  gap: 8px;
  width: 100%;

  &--vertical {
    flex-direction: column;
  }

  &__user-image-wrapper {
    flex-shrink: 0;
  }

  &__user-image {
    // not using width as it's affecting some images when user-name is too long
    border-radius: 50%;
    object-fit: cover;
  }

  &__user-info {
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-left: 4px;
    text-align: left;
    max-width: calc(100% - 40px - 8px); // 100% - <user-image width> - <margin-left>
  }

  &__user-rating {
    display: flex;
    font-size: 1rem;
    
    svg {
      margin: 0;
      fill: red;
    }
  }
}