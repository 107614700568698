@import "styles/resources/mixins.scss";

.gallery-edit {
  &__primary-field {
    display: flex;
    width: 80%;
    margin-top: 8px;

    @include media(">tablet") {
      width: 60%;
    }

    label {
      margin: 0;
    }
  }

  &__max-limit {
    width: 60%;
    margin-left: 40px;

    p {
      margin-left: 0;
    }
  }

  &__max-limit-input {
    max-width: 200px;
  }

  &__divider {
    margin-top: 16px;
  }

  &__help {
    white-space: pre-line;

    &__title {
      margin-top: 0;
      line-height: 22px;

      @include media(">tablet") {
        line-height: 18px;
      }
    }
  }

  &__watermark {
    max-width: 400px;
    cursor: pointer;
  }

  &__watermark-loading {
    margin: 24px;
  }

  &__watermark-buttons {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    margin-top: 12px;
  }

  &__cover {
    margin-top: 24px;
    gap: 8px;
    display: flex;
    flex-direction: column;

    &__device-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &__photo-picker-btn-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &__width-container {
      // this container is necessary so that the aspect ration below is dependent on this width and not the device-wrapper width (which should be 100% to center the preview component)
      position: relative;
      // mobile with desktop preview
      width: 100%;

      // mobile with mobile preview
      &--mobile {
        width: 80%;
      }

      @include media(">tablet") {
        // desktop with desktop preview
        width: 90%;
        max-width: 700px;
        max-height: 50vh;

        // desktop with mobile preview
        &--mobile {
          max-width: 340px;
          max-height: 70vh;
        }
      }
    }

    &__aspect-ratio-container {
      // mobile with desktop preview
      margin-top: 68%; // 16:9 aspect ration + some more margin to avoid overlaping

      // mobile with mobile preview
      &--mobile {
        margin-top: 156%;
      }

      @include media(">tablet") {
        // desktop with desktop preview
        margin-top: 56%; // 16:9 aspect ratio

        // desktop with mobile preview
        &--mobile {
          margin-top: 156%;
        }
      }
    }

    &__preview {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      pointer-events: none;
      width: 100%;
      height: 100%;
    }

    &__color-picker-list {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }

    &__options {
      gap: 16px;

      @include media(">small-tablet") {
        gap: 48px;
      }

      &__left {
        min-width: 160px;
      }
    }

    &__color-picker-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__color-picker {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;

      &__color-box {
        cursor: pointer;
        width: 20px;
        height: 20px;
        border: 1px solid lightgray;
      }
    }
  }

  &__layout {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;

    &__previews {
      position: relative;
    }

    &__desktop-frame {
      display: flex;
      align-items: center;
      flex-direction: column;

      &__outer-screen {
        border: 1px solid gray;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: gray;
      }

      &__screen {
        margin: 6px;
        background-color: white;
        border: 1px solid gray;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }

      &__bottom {
        width: 100%;
        height: 16px;
        border: 1px solid gray;
        margin-top: -1px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: gray;
      }

      &__column {
        width: 8px;
        height: 16px;
        border: 1px solid gray;
        margin-top: -1px;
        background-color: gray;
      }

      &__base {
        width: 64px;
        height: 8px;
        border: 1px solid gray;
        margin-top: -1px;
        background-color: gray;
      }
    }

    &__iphone-frame {
      position: absolute;
      right: -16px;
      bottom: -16px;
      width: 95px;
      height: 180px;
      border: 1px solid gray;
      border-radius: 12px;
      background-color: gray;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
      overflow: hidden;

      @include media(">small-tablet") {
        position: relative;
        right: 0;
        bottom: 0;
        width: 128px;
        height: 230px;
      }

      &__screen {
        position: absolute;
        top: 4px;
        left: 4px;
        right: 4px;
        bottom: 4px;
        border-radius: 8px;
        overflow: hidden;
        background-color: white;
        padding-top: 28px;
      }

      &__dynamic-island {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8px;
        width: 40%;
        height: 14px;
        background-color: gray;
        border-radius: 16px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      }
    }

    &__desktop-preview {
      overflow: hidden;
      width: 310px;
      height: 200px;
      padding-top: 8px;
    }

    &__mobile-preview {
      width: 100%;
      height: 100%;
      border: 0;
    }

    &__preview-box {
      width: 100%;
      background: lightgray;
      border-radius: 4px;

      &--rect-angles {
        border-radius: 0;
      }
    }
  }
}
