.modal-action-buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 5px 0;

  &__button {
    &:not(:last-child) {
      margin-right: 15px;
    }

    &--left {
      margin-right: auto !important;
    }
  }
}
