@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.user-settings {
  padding: 30px 5px;
  min-height: calc(100vh - 439px - 60px);

  @include media(">desktop") {
    padding: 30px 100px;
  }

  &__main {
    display: flex;
    flex-direction: column;

    @include media(">desktop") {
      margin-top: 65px;
      flex-direction: row;
    }  
  }

  &__tabs {
    margin: 20px 0;

    &__item {
      color: $text__grey-color;
      min-width: 148px;
      
      &:not(:first-child) {
        margin-top: 20px;
      }

      &:hover {
        color: $text__default-color;
      }
    }

    &__item-text {
      &--selected {
        &::before {
          content: '- ';
        }

        @include media(">desktop") {
          &::before {
            content: none;
          }
        }
      }
    }

    @include media(">desktop") {
      margin: 0;
      padding: 0 20px;
    }
  }

  &__content {
    flex-grow: 1;

    @include media(">desktop") {
      margin-left: 25px;
    }  
  }

  &__lading {
    position: fixed;
    top: 50%;
    left: 50%;
  }
}