@import "styles/theme/colors.scss";
@import "styles/resources/mixins.scss";

.ui-selector {
  justify-content: flex-end;

  &__view {
    background: transparent !important;
    color: $text__grey-color !important;
    box-shadow: none !important;
    
    &--selected {
      color: $text__primary-color !important;
    }

    &__separator {
      color: $light-grey;
    }
  }
}