@import "styles/theme/box-styles.scss";
@import "styles/resources/mixins.scss";

.dashboard {
  display: flex;
  position: relative;
  flex-direction: row;

  &__column {
    display: flex;
    flex-direction: column;
    width: 100%;

    &:first-child {
      margin-right: 8px;
    }

    &:not(:first-child) {
      margin-left: 8px;
    }

    @include media(">tablet") {
      width: 50%;
    }
  }

  &__box {
    width: 100%;
    margin-bottom: 15px !important;
  }

  &__widget {
    width: 100%;
    padding: 5px;
    box-shadow: $emphasis-box-shadow !important;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: white;

    &:not(:first-child) {
      margin-top: 15px;
    }

    @include media(">tablet") {
      padding: 20px;
    }
  }
}