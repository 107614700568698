@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.events {
  position: relative;
  min-height: 600px;

  &__list {
    display: block;
    margin-top: 12px;
    flex-grow: 1;

    @include media(">small-tablet") {
      // grid view
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  &__event {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 8px;
    background: white;
    border-radius: 8px;
    margin-bottom: 20px;
    cursor: pointer;
    width: 100%;
    border: 1px solid $light-border;

    @include media(">small-tablet") {
      // grid view - 2 columns
      min-width: calc(50% - 8px);
      max-width: calc(50% - 8px);
      flex: 1;
    }

    @include media(">tablet") {
      // grid view - 3 columns
      min-width: calc(33% - 8px);
      max-width: calc(33% - 8px);
    }

    @include media(">desktop") {
      // grid view - 4 columns
      min-width: calc(25% - 8px);
      max-width: calc(25% - 8px);
    }

    @include media(">bigScreen") {
      // grid view - 5 columns
      min-width: calc(20% - 8px);
      max-width: calc(20% - 8px);
    }

    &--deleting {
      filter: blur(1.2px)
    }

    &__relative-date {
      position: absolute;
      right: 8px;
      top: -9px;
      display: flex;
      background: #f1f1f1;
      padding: 3px 8px;
      border-radius: 8px;
      align-items: center;
      
      svg {
        font-size: 1rem;
        margin-right: 4px;
      }
    }

    &__actions {
      display: flex;
      position: relative;
      gap: 8px;
      margin-top: 8px;
      margin-left: auto;
    }
  }

  &__section-title {
    margin-top: 28px;
  }

  &__item {
    position: relative;
    margin-top: 17px + 10px;
    padding: 10px;
    border-radius: 12px !important;

    &--deleting {
      filter: blur(1.2px)
    }
  }

  &__relative-date {
    position: absolute;
    right: 8px;
    top: -9px;
    display: flex;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 3px 8px;
    border-radius: 8px;
    align-items: center;
    
    svg {
      font-size: 1rem;
      margin-right: 4px;
    }
  }

  &__user {
    margin-left: 6px;
    margin-top: 4px;
    margin-bottom: 8px;

    &--empty {
      display: flex;
      align-items: center;
      height: 28px;
    }
  }

  &__pack {
    width: fit-content;

    &--no-pack {
      height: 37px;
    }

    &__link {
      margin-bottom: 12px;
      font-size: 0.9rem;
    }

    svg {
      margin: 0 8px;
    }
  }

  &__event-title {
    margin-top: auto;
    padding-top: 16px;
    margin-left: 8px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin: 4px 0px 4px auto;
  }

  &__deleting-icon {
    position: absolute;
    right: 8px;
    bottom: 8px;
  }
}