@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.mobile-menu {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $secondary-color;
  opacity: 0;
  transition: opacity 200ms ease-in, visibility 0s linear 200ms;
  z-index: z-index('mobile-menu');

  &--open {
    visibility: visible;
    opacity: 1;
    transition: opacity 300ms ease-out;

    body {
      overflow: hidden;
    }
  }

  &__content {
    padding: 8%;
    box-sizing: border-box;
    transform: translateY(-200px);
    transition: transform 200ms ease-in;

    &--open {
      transform: translateY(0);
      transition: transform 300ms ease-out;
    }

    &__icon {
      margin-right: 10px;
    }

    &__item {
      align-items: center;
      display: flex;
      font-size: 26px;
      line-height: 34px;
      padding: 15px;

      &--user {
        line-height: unset;
      }

      &--log-in,
      &--register {
        width: 100%;
        border-radius: 12px;
        border: 1px solid $primary-color;
        text-align: center;
        max-width: 400px;
      }

      &--register {
        background-color: $primary-color;
      }

      &--others {
        flex-direction: column;
        align-items: flex-start;

        &:not(:first-child) {
          padding-left: 20px;
          font: 1rem;
        }
      }

      &--backoffice {
        height: 54px;
        align-items: baseline;
      }
    }

    &__access-wrapper {
      font-size: 26px;
      line-height: 34px;
      padding: 15px;
      display: flex;
      justify-content: center;
    }
  }
}