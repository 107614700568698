@import "styles/resources/mixins.scss";

.back-office-logo-link {
  display: flex;
  align-items: baseline;
  height: 20px;
  font-size: 1rem;

  &__image {
    height: 100%;
  }
}
