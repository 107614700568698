@import "styles/resources/mixins.scss";

:root {
  --search-input-left-offset: 0px;
}

$SEARCH_BAR_ANIMATION_DURATION: 250ms;
$SEARCH_BAR_CONTENT_ANIMATION_DURATION: 250ms;

.header-search-bar__wrapper {
  position: relative;
  width: 35px;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 1;

  @include media(">tablet") {
    width: 170px;
    margin-right: 20px;
  }

  &--open {
    z-index: z-index('header-search-bar');
  }
}

.header-search-bar {
  display: flex;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  align-items: center;
  width: 35px;
  height: 35px;
  left: 0;
  transition: all $SEARCH_BAR_ANIMATION_DURATION ease;
  transition-delay: $SEARCH_BAR_CONTENT_ANIMATION_DURATION;
  z-index: 11;

  @include media(">tablet") {
    border-radius: 10px;
    width: 100%;
  }

  &--open {
    justify-content: unset;
    border-radius: 0;
    left: calc(-1 * var(--search-input-left-offset));
    width: 100vw;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    transition: all $SEARCH_BAR_ANIMATION_DURATION ease, border-radius 0s;
    transition-delay: 0;
  }

  &__icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-left: 7px; // working with static values because of the animation
    z-index: 1;

    @include media(">tablet") {
      padding: 0px 16px;
    }
  }

  &__input-root {
    color: inherit;
    width: 100%;

    ::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  &__input-form {
    width: 100%;
    position: relative;
  }

  &__input {
    padding: 8px 8px 8px 0px !important;
    padding-left: calc(1em + 32px) !important;
    opacity: 0; /* required on iOS */
    
    &--open {
      padding-left: calc(1em + 32px + 20px) !important;
      opacity: 1;
      width: calc(100% - (1em + 187px)) !important;

      @include media(">tablet") {
        width: calc(100% - (1em + 132px))
      }
    }

    @include media(">tablet") {
      opacity: 1;
    }

    &__action-buttons {
      display: flex;
      position: absolute;
      right: 0;
      top: 0;
      align-items: center;
      margin-right: 15px;

      &--close-button {
        width: 25px;
        height: 25px;
      }

      &--submit-button {
        width: 40px;
        height: 40px;
      }

      svg {
        width: 16px;
      }

      @include media(">tablet") {
        margin-right: calc(1em + 32px + 20px);
      }
    }
  }

  &__input-line {
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 11px;
    background-color: black;
    transition: width $SEARCH_BAR_ANIMATION_DURATION ease-out;
    transition-delay: 0;
    margin-left: calc(1em + 32px + 20px);
    
    &--open {
      width: calc(100% - (1em + 32px + 20px + 15px));
      transition-delay: $SEARCH_BAR_ANIMATION_DURATION + $SEARCH_BAR_CONTENT_ANIMATION_DURATION;

      @include media(">tablet") {
        width: calc(100% - (1em + 32px + 20px) * 2);
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100vw;
    min-height: 400px;
    max-height: calc(100vh - 150px);
    transform: scaleY(0);
    transform-origin: 50% 0;
    top: 60px;
    left: calc(-1 * var(--search-input-left-offset));
    background-color: white;
    transition: all $SEARCH_BAR_CONTENT_ANIMATION_DURATION ease;
    transition-delay: 0;
    opacity: 0;
    z-index: 11;
    overflow: scroll;
    padding: 10px;
    box-sizing: border-box;

    ol {
      padding: 0;
    }

    li {
      list-style: none;
    }

    &--open {
      transform: scaleY(1);
      transition-delay: $SEARCH_BAR_ANIMATION_DURATION;
      opacity: 1;
    }

    @include media(">tablet") {
      padding: 20px 45px;
    }  
  }

  &__recommended-categories {
    margin-top: auto;
  }

  &__blocks-list-wrapper {
    height: 120px;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: scroll;
    padding-bottom: 7px;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 120vw;
    height: 100vh;
    background: #111;
    opacity: .3;
    z-index: 10;
  }
}