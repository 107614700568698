.gallery-photos-skeleton {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 8px;

  &--photo-skeleton {
    border-radius: 10px;
    margin-bottom: 8px;
  }

  &--rect-angles {
    border-radius: 0;
  }
}
