@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.gallery {
  min-height: 90vh;

  &__private-gallery {
    position: absolute;
    top: 0;
    width: 100%;
    background: $primary-color;
    color: $text__default-color;
    z-index: 1;
    text-align: center;

    p {
      margin: 2px;
    }
  }

  &__cover {
    // maintain initial area while loading
    height: 100svh; // svh => smallest viewport height
  }

  &__download-cta {
    display: flex;
    justify-content: flex-end;
    margin: 8px 10px -2px 16px
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 12px 4px;

    &__nr-photos-label {
      color: $text__secondary-color;
      font-size: 0.9rem;
    }

    &__marked-photos-sub-menu {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: flex-end;
      margin-left: auto; // in case nr-photos-label is not present
    }
  
    &__marked-photos-value {
      margin: 0;
      font-size: 0.9rem;
      color: $text__secondary-color;
    }
  }

  &__photo {
    position: relative;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      .gallery-image__bottom-action-buttons {
        visibility: visible;
      }
    }
  }

  &__snackbar {
    background: white;
    border-radius: 4px;
    padding: 8px 16px;
    color: $text__secondary-color;

    &__label {
      margin: 8px 0;

      &--bold {
        font-weight: bold;
      }
    }
  }
}