// imported from Google fonts: https://fonts.google.com/specimen/League+Spartan?preview.text=Hello!&preview.text_type=custom
// TODO: optimize fonts https://wpspeedmatters.com/self-host-google-fonts/ & https://type-scale.spencermortensen.com/5/3.25/1/17px/League%20Spartan%20700/Roboto%20300
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@200;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.text {
	font-family: 'Roboto';
	font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;;  
  margin: 0;
  color: $text__default-color;
  white-space: pre-line;
  text-transform: none;

  /*
  * Type Variants
  */

  &--title {
    font-family: 'League Spartan';
    font-size: 40px;
    line-height: 44px;
    font-weight: 900;

    @include media(">tablet") {
      font-size: 64px;
      line-height: 72px;
    }

    @include media(">bigScreen") {
      font-size: 80px;
      line-height: 96px;
    }
  }

  &--title2 {
    font-family: 'League Spartan';
    font-size: 32px;
    line-height: 24px;
    font-weight: 500;

    @include media(">tablet") {
      font-size: 32px;
      line-height: 40px;
    }

    @include media(">bigScreen") {
      font-size: 40px;
      line-height: 48px;
    }
  }

  &--title3 {
    font-family: 'League Spartan';
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;

    @include media(">tablet") {
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2rem;
    }

    @include media(">bigScreen") {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }
  }

  // same as subtitle for now but better to be here in case there is any change in the future
  &--title4 {
    font-family: 'League Spartan';
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;

    @include media(">tablet") {
      font-size: 22px;
      line-height: 26px;
    }
  }

  &--subtitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }

  &--subtitle2 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  &--subtitle3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  &--body2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }

  /*
  * Color Variants
  */

  &--default-color {
    color: $text__default-color
  }

  &--primary-color {
    color: $text__primary-color
  }

  &--secondary-color {
    color: $text__secondary-color
  }

  &--light-color {
    color: $text__light-color
  }

  &--grey-color {
    color: $text__grey-color
  }

  &--enhanced-grey-color {
    color: $text__enhanced-grey-color
  }

  &--white-color {
    color: $text__white-color
  }

  &--disabled-color {
    color: $text__disabled-color
  }

  /*
  * Special Variants
  */

  &--with-vertical-limit {
    // default limit to 2 lines
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &--with-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--bold {
    font-weight: bold;
    letter-spacing: 0.5px;
  }

  b {
    letter-spacing: 0.5px;
  }

  &--uppercase {
    text-transform: uppercase;
  }
}
