.verify-email-modal {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__loading {
    margin: 16px 0;
  }
}