@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.event-gallery-image {
  position: relative;
  box-sizing: border-box;

  border-radius: 10px;
  border: 2px solid $app-background-color;

  &--selected {
    border: 2px solid $third-color;
  }

  &--non-selectable {
    user-select: none; // prevent being selected and void conflicts with custom selecting logic
  }

  &--rect-angles {
    border-radius: 0;
  }

  &:hover {
    .event-gallery-image__checkbox {
      display: block;
    }
  }

  &__image {
    width: 100%;
    border-radius: 10px;

    &--blurred {
      img {
        filter: blur(4px);
      }
    }

    &--rect-angles {
      border-radius: 0;
    }
  }

  &__error-tooltip {
    position: absolute;
    left: 8px;
    bottom: 8px;
    z-index: 9;

    &__title {
      font-size: 0.9rem;

      @include media(">desktop") {
        font-size: 1rem;
      }
    }
  }

  &__mark {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    z-index: 1;
    margin: 12px;
    background: #ffffff35;
    border-radius: 50%;
    color: rgba(0, 0, 0, 0.54);

    &--shifted {
      left: 48px;
      @include media(">tablet") {
        left: 54px;
      }
    }  
  }

  &__checkbox {
    display: none;
    position: absolute;
    right: 8px;
    bottom: 8px;

    svg {
      color: $third-color;
    }

    .event-gallery-image:hover {
      display: block;
    }

    &--visible {
      display: block;
    }

    &:focus {
      display: block;
    }
  }

  &__bottom-shade-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    overflow: hidden;

    &--rect-angles {
      border-radius: 0;
    }
  }

  &__bottom-shade {
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.18));
  }

  &__loading {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  &__linear-loading {
    position: absolute;
    width: 100%;
    bottom: 0px;
  }

  &__name {
    position: absolute;
    bottom: 4px;
    left: 8px;
    max-width: 80%;
  }
}
