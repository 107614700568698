@import "styles/resources/mixins.scss";

.user-gallery {
  position: relative;
  width: 100%;

  &--with-min-height {
    min-height: 300px;
  }

  &__image {
    border-radius: 10px;
    object-fit: cover;
  }

  &__info-wrapper {
    width: 100%;
    z-index: 1;
  }

  &__info {        
    text-align: center;

    &__title {
      margin: 10px;

      // limit to 2 lines
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 10px 10px;
      font-size: 16px;

      @include media(">tablet") {
        margin: 10px 48px;
      }

      &--alone {
        margin-bottom: 38px;
      }
    }

    &__photos-count {
      justify-content: center;
      margin: 8px 4px;
      color: gray;
      font-size: 0.9rem;
    }
  }

  &--clickable {
    cursor: pointer;
  }
}