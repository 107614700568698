@import "styles/resources/mixins.scss";

.react-joyride__tooltip {
  white-space: pre-line;
}

.backoffice {
  display: flex;
  flex-direction: column;

  @include media(">tablet") {
    flex-direction: row;
    height: calc(100vh - 60px); // keep all backoffice fixed besides content
  }

  &__side-bar {
    position: fixed !important;
    width: 240px;
    flex-shrink: 0;
    white-space: nowrap;
    transition: all 300ms ease-out !important;
    margin-top: 61px;
    display: flex;

    position: absolute !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;

    &--open {
      width: 240px;
    }

    &--close {
      overflow-x: hidden;
      width: 68px;
    }

    &__paper {
      position: absolute !important;

      &--open {
        width: 240px;
      }

      &--close {
        overflow-x: hidden;
        width: 68px;
      }
    }

    &__header {
      min-height: 60px - 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px;
    }
  }

  &__content {
    width: 100%;
    padding: 5px;
    padding-top: 20px;
    box-sizing: border-box;
    z-index: 0;

    @include media(">tablet") {
      padding: 20px;
      // keep all backoffice fixed besides content
      height: calc(100vh - 60px);
      overflow: scroll;
    }
  }
}