.password-settings {
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: 16px;
  }

  &__action-button {
    position: relative;
    margin-top: 30px;
    width: fit-content;
  }

  &__action-button-loading {
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
  }

  &__alert {
    position: absolute;
    margin-top: 20px;
  }

  &__mfa {
    &__title {
      margin-top: 32px;
      margin-bottom: 16px;
    }

    &__description {
      margin-bottom: 16px;
    }
  }
}