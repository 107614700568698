@import "styles/theme/colors.scss";
@import "styles/resources/mixins.scss";

$ARROW_BUTTON_WIDTH: 48px;
$NR_OF_DAYS_DISPLAYED: 6;
$NR_OF_DAYS_DISPLAYED_MOBILE: 4;
$LEFT_MARGIN_DAY: 10px;
$LEFT_MARGIN_DAY_MOBILE: 0px;

.week-calendar {
  width: 100%;

  &__header {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;

    &__button {
      border-radius: 50%;
    }
  }

  &__picker {
    display: flex;
    align-items: center;
  }

  &__side-arrow {
    margin: 0;
    z-index: 1;
  }

  &__list {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    justify-content: space-around;
    width: calc(100% - #{$ARROW_BUTTON_WIDTH} * 2);
  }

  &__list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    width: calc(100% / #{$NR_OF_DAYS_DISPLAYED_MOBILE} - #{$LEFT_MARGIN_DAY_MOBILE});
    max-width: 50px;

    @include media(">tablet") {
      width: calc(100% / #{$NR_OF_DAYS_DISPLAYED} - #{$LEFT_MARGIN_DAY});
    }

    &:hover {
      background-color: $primary-color--light;
    }

    &--selected {
      background-color: $primary-color;

      &:hover {
        background-color: $primary-color--light;
      }
    }

    &__button {
      padding: 6px 8px;
      padding-bottom: 12px;
      cursor: pointer;
      border: 0;
      background: none;
      font-size: inherit;

      @include media(">tablet") {
        padding: 6px 12px;
      }
    }

    &:not(:first-child) {
      margin-left: $LEFT_MARGIN_DAY_MOBILE;

      @include media(">tablet") {
        margin-left: $LEFT_MARGIN_DAY;
      }
    }

    &__day {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 8px;
      font-size: 16px;
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      background-color: $background__primary-color;

      &--today {
        background-color: $secondary-color;
        color: $text__default-color;
      }
  
      &--with-events {
        &:after {
          content: '';
          position: absolute;
          bottom: 4px;
          width: 2px;
          height: 2px;
          border: 1px solid;
          border-radius: 50%;
          font-size: 2rem;
          line-height: 5px;
          background: $primary-color;
          color: $primary-color;
        }
      }
    }
  }
  
  &__content {
    margin-top: 20px;
    
    &__events {
      margin-top: 12px;
      overflow-y: scroll;
    }

    &__no-events {
      padding: 12px;
    }

    &__events-list {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    
    &__event {
      padding: 10px;
      margin: 0;

      &:first-child {
        margin-top: 20px;
      }
    }

    &__event-title {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &__event-time {
      display: flex;
      flex-direction: column;

      p {
        margin: 0;
        margin-left: 10px;
      }
    }

    &__divider {
      margin: 10px 15% 0 15%;
    }
  }

  &__action-button {
    float: right;
  }
}
