.event-date {
  margin-top: 20px;

  &__date {
    display: flex;
    flex-direction: column;
  }

  &__icon-info {
    margin-bottom: 8px;
    font-size: 0.9rem;

    svg {
      // align with top user avatar
      width: 36px;
    }
  }
}