.comment {
  display: flex;

  &__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-shrink: 0;
    object-fit: cover;
  }

  &__content {
    margin-left: 1em;
    width: 100%;
  }

  &__header {
    display: flex;

    &__author {
      font-size: 1em;
      color: rgba(0,0,0,.87);
      font-weight: 700;
      text-decoration: none;
    }

    &__metadata {
      margin-left: .5em;
      color: rgba(0,0,0,.4);
      font-size: .875em;
    }
  }

  &__rating {
    align-items: center;
    display: flex;

    &__raw-value {
      margin-left: 10px;
    }
  }

  &__body {
    margin: .25em 0 .5em;
    font-size: 1em;
    word-wrap: break-word;
    color: rgba(0,0,0,.87);
    line-height: 1.3;
    text-align: justify;
  }

  &__input-form {
    margin: 5px 0;
    display: flex;
    align-items: center;

    .MuiFormControl-root {
      width: 100%;
      
      .MuiInputBase-root {
        padding: 8px;
      }
    }

    &__input {
      width: 100%;
      min-height: 48px;
      margin-left: 15px;
      flex: 1;
    }

    &__button {
      margin-top: auto !important;
    }
  }

  &__input-form-buttons {
    display: flex;
    justify-content: flex-end;

    button {
      margin: 10px 5px 0px 5px;
    }
  }

  &__footer {
    display: flex;

    &__action {
      display: flex;
      margin: 0 .75em 0 0;
      color: rgba(0, 0, 0, .4);
      font-size: .875em;
      cursor: pointer;
      background: transparent;
      border: 0;
      padding: 0;

      &:hover {
        color: rgba(0, 0, 0, 1);
      }

      &--right {
        margin-left: auto;
      }
    }
  }
}