
@import "styles/resources/mixins.scss";

.key-value-input {  
  &__add-key-value {
    margin-top: 16px !important;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  &__key {
    width: 100%;
    
    @include media(">tablet") {
      width: 30%;
    }  
  }
  
  &__value {
    flex-grow: 1;
    margin-top: 16px !important;
  }

  &__options {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    button {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  &__favorite {
    height: 30px;
  }
}
