@import "styles/resources/mixins.scss";

.pack-details-skeleton {
  padding: 40px;

  &__main {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;

    &--left {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: space-between;
      height: 1000px;
    }

    &--right {
      display: none;
      margin-left: 40px;
      width: 35%;
      min-height: 300px;
      height: 50vh;
      max-height: 600px;

      @include media(">tablet") {
        display: block;
      }
    }
  }

}