@import "styles/theme/box-styles.scss";
@import "styles/theme/colors.scss";
@import "styles/resources/mixins.scss";

:root {
  --value-widget-icon-color: #{$primary-color};
}

.value-widget {
  margin-top: 10px;

  &__content {
    display: flex;
    justify-content: space-between;
    text-align: right;

    &__title-value {
      // necessary for when icon is not used
      width: 100%;
      margin-bottom: 10px;
    }

    &__title {
      font-weight: normal;
      color: $text__secondary-color;
      font-size: 1rem;
      margin: 5px 0;
    }

    &__value {
      font-weight: normal;
      font-size: 1.5rem;
      min-height: 28px; // maintain layout while loading
    }
  }

  &__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    background-color: var(--value-widget-icon-color);
    width: 75px;
    height: 75px;
    transform: translate(0, -25px);
    border-radius: 8px;
    box-sizing: border-box;

    &--small {
      width: 55px;
      height: 55px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    margin-top: 15px;
    min-height: 36px;

    &__text {
      display: flex;
      align-items: center;
    }

    &__icon {
      margin-right: 10px;
    }

    &__action-button {
      margin-left: auto;
    }
  }
}