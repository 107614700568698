@import "styles/resources/mixins.scss";

:root {
  --item-padding: 5px;
}

.separator-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &--nowrap {
    flex-wrap: nowrap;
    overflow: scroll;
  }
  
  &--vertical {
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: center;

    &--vertical {
      align-items: unset;
      margin-right: 0;
      margin-bottom: var(--item-padding);
      flex-direction: column;
    }

    &--with-margin {
      margin-right: var(--item-padding);

      &:last-child {
        margin-right: 0;
      }  
    }
  }

  &__separator {
    margin-left: var(--item-padding);
    margin-right: var(--item-padding);
    line-height: 100%;

    &--vertical {
      margin-left: 0;
      margin-right: 0;
      margin-top: var(--item-padding);
      justify-content: center;
    }
  }
}