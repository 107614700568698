@import "styles/theme/colors.scss";
@import "styles/resources/mixins.scss";

.watermark-input {
  &__title {
    width: 100%;
      
    @include media(">tablet") {
      width: 40%;
    }
  }

  &__image-wrapper {
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 16px;
  }

  &__options {
    &__position {
      display: flex;
      flex-direction: column;

      @include media(">tablet") {
        flex-direction: row;
        gap: 16px;
      }
    }

    &__scheme-wrapper {
      flex-grow: 1;
      width: 50%;
    }

    &__position-example {
      flex-grow: 1;
      width: 100%;
      height: 260px;
      background-color: $primary-color;
      border-radius: 8px;
      margin-bottom: 8px;

      @include media(">tablet") {
        width: 400px;
      }

      @include media(">desktop") {
        height: 320px;
      }

      @include media(">bigScreen") {
        height: 420px;
      }

      &__image {
        max-width: 100%;
        max-height: 100%;
      }

      &__image-wrapper {
        height: 100%;
        background-repeat: no-repeat;
        border-radius: 8px;

        &--top_left {
          background-position-x: left;
          background-position-y: top;  
        }
        &--top_center {
          background-position-x: center;
          background-position-y: top;
        }
        &--top_right {
          background-position-x: right;
          background-position-y: top;
        }
        &--center_left {
          background-position-x: left;
          background-position-y: center;
        }
        &--center {
          background-position-x: center;
          background-position-y: center;
        }
        &--center_right {
          background-position-x: right;
          background-position-y: center;
        }
        &--bottom_left {
          background-position-x: left;
          background-position-y: bottom;
        }
        &--bottom_center {
          background-position-x: center;
          background-position-y: bottom;
        }
        &--bottom_right {
          background-position-x: right;
          background-position-y: bottom;
        }
        &--expanded {
          background-position-x: center;
          background-position-y: center;
          background-size: contain;
        }
        &--repeat {
          background-repeat: repeat;
        }
      }
    }

    &__scheme {
      display: flex;
      width: 80px;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 12px;
      margin-left: 12px;
    }

    &__scheme-item {
      width: 20px;
      height: 20px;
      border: 1px solid black;
      flex-grow: 1;
      cursor: pointer;

      &:hover {
        border: 2px solid black;
        background-color: $primary-color--dark;
      }

      &--selected {
        background-color: $primary-color;
      }
    }

    &__extra-positions {
      margin-left: 12px;
      margin-top: 12px;
    }

    &__size-select-wrapper {
      margin-top: 12px;
      width: 200px;
    }

    &__size-select {
      width: 100%;
    }

    &__opacity-slider {
      margin-top: 24px;
      width: 200px; 
    }
  }
}
