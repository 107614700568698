@import "styles/resources/mixins.scss";

.share-popover {
  padding: 12px;
  min-width: 360px;

  &__title {
    margin-bottom: 8px;
  }

  &__share-checkbox {
    align-items: center;
    display: flex;
    margin-top: 12px;

    label {
      margin: 0;
    }
  }

  &__divider {
    margin: 16px 0;
  }

  &__uploading-gallery {
    align-self: center;
  }

  &__primary-field {
    display: flex;
    width: 80%;
    margin-top: 8px;

    label {
      margin: 0;
    }
  }


  &__help {
    &__title {
      margin-top: 0;
      line-height: 22px;

      @include media(">tablet") {
        line-height: 18px;
      }
    }
  }
}
