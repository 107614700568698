@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.faqs {
  margin: 5px;

  @include media(">tablet") {
    margin: 20px;
  }

  &__section {
    margin: 0 5px;

    @include media(">tablet") {
      margin: 0 20px;
    }

    &__title {
      margin: 24px 0 16px 0;
    }
  }

  &__faq {
    &__answer {
      white-space: pre-line;
      text-align: justify;
    }
  }
}