@import "styles/theme/colors.scss";

.side-bar {
  position: relative;
  width: 68px;
  z-index: 1;

  &__list-wrapper {
    position: absolute;
    width: 68px;
    overflow: hidden;
    border-right: 1px solid $light-grey;
    z-index: 999;
    height: 100%;
    transition: width 0.25s ease-in-out;
    background-color: $app-background-color;

    &--open {
      width: 240px;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    &__item {
      display: flex;
      justify-content: center;
      margin: 8px;
      border-radius: 8px;
      justify-content: center;

      &--active {
        background-color: $background__primary-color;
      }

      &:not(:first-child) {
        margin-top: 20px;
      }

      &--menu {
        min-height: 40px;
        align-items: center;
        justify-content: flex-end;
        padding: 10px 10px 10px 44px;
      }
    }

    &__divider {
      margin: 0 20px;
      border: 0;
      border-bottom: 1px solid $light-grey;
    }

    &__item-button {
      display: flex;
      width: 100%;
      padding: 10px 10px 10px 14px;
      align-items: center;
      background: none;
      text-align: left;
      border: none;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        background-color: $primary-color--light;
      }

      &__text {
        margin-top: 4px;
      }
    }
  }
}