@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.beam-studio {
  &__backoffice-logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__backoffice-logo {
    max-width: 200px;

    @include media(">small-tablet") {
      max-width: 230px;
    }

    @include media(">desktop") {
      max-width: 320px;
    }
  }

  &__sub-title {
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: normal;
    padding: 0 10px;

    @include media(">tablet") {
      padding: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin: 0;

    @include media(">tablet") {
      flex-direction: row;
      margin: 0 40px;
    }

    @include media(">desktop") {
      margin: 0 15%;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 5px;
    flex-basis: 60%;

    @include media(">tablet") {
      margin: 0;
      margin-top: 40px;
    }
  }

  &__image-wrapper {
    display: flex;
    flex-basis: 40%;
    align-items: center;

    @include media(">tablet") {
      margin-left: 50px;
    }

    &__image {
      width: 100%;
      border-radius: 12px;
      object-fit: cover;
      max-height: 260px;

      @include media(">tablet") {
        max-height: unset;
      }
    }
  }

  &__item {
    width: 100%;
    border-bottom: 1px solid $primary-color;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 16px;
      cursor: pointer;
    }

    &__open-button {
      visibility: visible;

      &--hide {
        visibility: hidden;
      }
    }

    &__collapsible {
      height: 0;
      opacity: 0;
      transition: all 200ms ease-out;

      &--open {
        padding-bottom: 16px;
        transition: all 200ms ease-out;
        opacity: 1;
      }
    }
  }

  &__action-button {
    margin-top: 45px;
  }
}
