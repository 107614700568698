@import "styles/resources/mixins.scss";

.cards-layout {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-wrap: wrap;

  &__sizer {
    width: 50%;

    @include media(">desktop") {
      width: 33%;
    }
  }

  &__add-card-wrapper {
    border-radius: 10px;
    height: auto;

    &--first-card {
      width: 100%;

      @include media(">tablet") {
        width: 50%;
      }

      @include media(">desktop") {
        width: 33%;
      }
    }
  }

  &__add-card {
    display: flex;
    justify-content: space-around;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 200px;
    // border background-image generator: https://kovart.github.io/dashed-border-generator/
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%2333333375' stroke-width='4' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    border-radius: 12px;

    @include media(">tablet") {
      min-height: 260px;
    }

    &__title {
      text-align: center;
      margin-bottom: 24px;
    }

    &__text {
      margin: 10px 10px 24px 10px;
      text-align: center;
      line-height: 2rem;
    }
  }
}