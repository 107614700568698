@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";
@import "styles/utils/hide-scrollbar.scss";

.pricing {
  
  &__scroll-wrapper {
    @include hideScrollbar;

    width: 100vw;
    overflow: scroll;
    scroll-snap-type: x mandatory;

    @include media(">tablet") {
      width: 100%;
      overflow: none;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__content {
    min-width: 800px;
    padding: 0 48px;

    @include media(">tablet") {
      width: 100%;
      max-width: 1600px;
    }
  }

  &__title {
    text-align: center;
    padding: 0 60px !important;
    margin: 32px 0;

    @include media(">desktop") {
      padding: 0;
    }
  }

  &__grid {
    padding: 0;
    align-content: center;
    

    @include media(">desktop") {
      padding: 0 140px;
    }
  }

  &__plan {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    scroll-snap-align: start;

    @include media(">tablet") {
      scroll-snap-align: none;
    }

    &--highlighted {
      background-color: rgba(176, 187, 128, 0.3);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &__description {
      margin: 8px 0 12px 0;
    }

    &__action {
      margin: 10px 0 !important;
      width: 100%;
    }
  }

  &__table {
    &__section {
      border-radius: 8px;
      background-color: $primary-color;
      padding: 10px;
      margin-right: 10px;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.5;
      letter-spacing: 0;

      @include media(">tablet") {
        margin: 0px 0;
      }
    }

    &__section-name {
      left: 10px;
      width: 200px;
    }

    &__row {
      color: #20262D;
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0;
      font-weight: 400;
      

      &:hover {
        background-color: rgba(231, 241, 252, 0.4);
      }

      & > *:first-chi ld {
        position: sticky;
        left: 0px;
        background-color: $app-background-color;
        padding-left: 8px;

        @include media(">tablet") {
          padding-left: 20px;
          background-color: unset;
        }
      }
    }

    &__block {
      text-align: center;
      align-items: center;
      box-sizing: border-box;
      height: 100%;
      padding: 5px;
      display: flex;
      flex-direction: column;
      text-align: center;

      &--highlighted {
        background-color: rgba(176, 187, 128, 0.3) !important;
      }

      &--last-highlighted {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &--middle {
        background-color: rgba(243, 246, 249, 0.5);
        border-width: 0 1px 0 1px;
        border-style: solid;
        border-color: $secondary-color;
      }
    }

    &__block-icon {
      color: rgb(0, 0, 0);
    }

    &__block-title {
      display: flex;
      align-items: center;
      height: 100%;
    }

    &__block-sub-title {
      display: flex;
      align-items: center;
      height: 100%;
      text-indent: 18px;
      white-space: nowrap;
    }
  }
}
