.header-menu-item {
  &--back-office-logo {
    padding: 12px 18px !important;
  }

  &__user-logo {
    margin-right: 8px;
  }

  &__back-office-logo {
    width: 120px;
  }

  &__divider {
    margin: 8px 8px !important;
  }

  &__link {
    width: 100%;
  }
}
