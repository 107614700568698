:root {
  --item-width: 25%;
  --spacing: 10px;
}

.item-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: calc(-1 * (var(--spacing) / 2));

  &__item {
    width: calc(var(--item-width) - var(--spacing));
    box-sizing: border-box;
    margin: calc(var(--spacing) / 2);
  }
}