@import "styles/theme/colors.scss";
@import "styles/resources/mixins.scss";

:root {
  --layout-columns: 3;
}

.layout {
  position: relative;

  &__top-buttons {
    display: flex;
    align-items: center;
    margin: 0;
    margin-top: 12px;
    justify-content: space-between;

    @include media(">small-tablet") {
      margin: 12 24px;
    }

    &__refresh {
      margin-right: 8px !important;
    }

    &__left-group {
      display: flex;
      gap: 8px;
    }
  }

  &__options {
    display: flex;
    justify-content: flex-end;
  }

  &__action-buttons {
    position: fixed;
    bottom: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;

    &__button {
      background: $primary-color !important;
    }
  }

  &__empty-portfolio {
    margin-left: 16px;
  }

  &__layout-type-picker {
    display: flex;
    justify-content: center;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:first-child {
        margin-right: 50px;
      }
    }
  }

  &__editor-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__editor {
    width: 75%;
    margin-top: 8px;

    @include media(">small-tablet") {
      width: 50%;
    }

    @include media(">tablet") {
      width: 33%;
    }

    @include media(">bigScreen") {
      width: 40%;
    }

    &--big {
      width: 100%;

      @include media(">small-tablet") {
        width: 60%;
      }
  
      @include media(">tablet") {
        width: 40%;
      }
  
      @include media(">bigScreen") {
        width: 50%;
      }
    }
  }

  &__mansory {
    &__item-wrapper {
      width: 100%;
    }

    &__item {
      padding: 5px;
      box-sizing: border-box;
    }

    &__item-image {
      width: 100%;
      border-radius: 10px;

      &--editing {
        &:nth-child(2n) {
          animation: shake 0.5s;
          transform-origin: 50% 10%;
          animation-direction: alternate;
          animation-iteration-count: infinite;
        }
  
        &:nth-child(2n-1) {
          animation: shake2 0.5s;
          transform-origin: 30% 5%;
          animation-direction: alternate;
          animation-iteration-count: infinite;
        }  
      }

      &--uploading {
        filter: opacity(0.7);
      }

      &--dragging {
        opacity: 0.4;
      }
    }

    &__reorder-icon {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 14px !important;
    }

    &__upload-feedback {
      position: absolute;
      bottom: 5px;
      right: 5px;
    }
  }

  &__modal {
    &__slider {
      margin: 40px 0;
      width: 300px;
    }
  }
}

@keyframes shake {
  0% {
    transform: rotate(-0.2deg);
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(0.4deg);
    animation-timing-function: ease-out;
  }
}

@keyframes shake2 {
  0% {
    transform: rotate(0.2deg);
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(-0.4deg);
    animation-timing-function: ease-out;
  }
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in IE 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}
