@import "styles/resources/mixins.scss";

.gallery-image {
  &__image {
    width: 100%;
    border-radius: 10px;

    &--blurred {
      img {
        filter: blur(4px);
      }
    }

    &--non-selectable {
      // prevent Safari long press
      -webkit-touch-callout: none;
    }

    &--rect-angles {
      border-radius: 0;
    }
  }

  &__action-buttons {
    position: absolute;
    display: flex;
    z-index: 1;
  }

  &__bottom-action-buttons {
    visibility: visible;
    position: absolute;
    bottom: 0;
    right: 0;
    transition: all 500ms linear; // delay hidding

    @include media(">tablet") {
      visibility: hidden;
    }

    &--visible {
      visibility: visible;
    }
  }

  &__mark {
    position: relative;
    margin: 8px;
    background: #ffffff35;
    border-radius: 50%;
  }

  &__loading {
    position: absolute;
    left: 0px;
    top: 0px;
    color: white;
  }
}
