@import "styles/theme/colors.scss";

.back-office-footer-menu {
  position: fixed;
  bottom: 4px;
  left: 4px;
  width: calc(100vw - 8px);
  background-color: $app-background-color;
  opacity: 0.99;
  z-index: 999;
  transform: translateY(0);
  transition: transform 0.4s ease-out;
  padding-bottom: env(safe-area-inset-bottom);
  border-radius: 8px;
  border: 1px solid #e9e9e9;

  &--hidden {
    transform: translateY(200px);
  }

  ul {
    display: flex;
    justify-content: space-around;
  }

  &__list-item {
    width: auto !important;
    padding: 0px !important;
  }

  &__list-item-icon {
    min-width: auto !important;

    &--active {
      color: $button__primary-color !important;
    }
  }

  &__list-item-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.6rem;
    gap: 2px;
  }
}