.gallery-card {
  width: 100%;

  &__photo {
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }

  &__info {
    margin-left: 10px;
    margin-bottom: 16px;

    &__title {
      margin: 4px 0;
    }
  }

  &__event {
    display: block;
    color: gray;
    margin: 4px 0;
    font-size: .9rem;
    width: fit-content;
  }
}

.gallery-card-skeleton {
  &__image {
    border-radius: 8px;
  }
}
