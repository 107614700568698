@import "styles/theme/colors.scss";
@import "styles/resources/mixins.scss";

.sign-up {
  height: 100vh;

  &__logo {
    position: absolute;
    width: 100px;
    margin-top: 10px;
    margin-left: 10px;
    z-index: 1;

    @include media(">small-tablet") {
      margin-top: 30px;
      margin-left: 60px;
    }

    img {
      width: 100%;
    }
  }

  &__form-wrapper {
    box-sizing: border-box;
    height: 100%;
    justify-content: center;
    max-width: 360px;
    padding: 30% 24px 8px 24px;

    @include media(">small-tablet") {
      padding-top: 40%;
    }

    @include media(">bigScreen") {
      padding-top: 300px;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    height: 100%;

    &--left {
      display: none;
      position: relative;
      width: 100%;
      flex-grow: 1;
      margin-right: 20px;
      
      @include media(">small-tablet") {
        display: block;
        min-width: 40%;
        max-width: 600px;
        padding: 40px 10px 0 0;
      }
    }

    &--right {
      justify-content: center;
      display: flex;
      width: 100%;
      height: 100%;
      position: relative;

      @include media(">small-tablet") {
        width: 60%;
      }
    }

    &__image {
      position: absolute;
      display: none;
      object-fit: cover;
      max-width: 1200px;
  
      @include media(">small-tablet") {
        display: initial;
        min-height: 600px;
        height: 100%;
        width: 100%;
        top: 0;
        border-radius: 0 30px 30px 0;
      }
    }
  }
}
