@import "styles/theme/colors.scss";

.plan-badge {
  display: inline-block;
  padding: 8px 12px;
  border-radius: 16px;
  background-color: $primary-color;

  &--small {
    padding: 4px 8px; 
    font-size: 0.7rem;
  }
}
