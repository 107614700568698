.menu-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__edit-menu {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 48px;
    z-index: 1;
  }

  &__menu-button {
    background: #7b79792b !important;
    border-radius: 50%;

    &:hover {
      background: #7b797969 !important;
    }
  }
}
