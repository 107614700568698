@import "styles/theme/colors.scss";
@import "styles/resources/mixins.scss";

.sign-up-form {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__title {
    color: rgba(0,0,0,0.87);
    direction: ltr;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    text-align: left;
  }

  &__form {
    max-width: 360px;

    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    &__text-field {
      padding-bottom: 24px;
    }

    &__action-buttons {
      display: flex;
      justify-content: flex-end;
      
      &--multiple {
        justify-content: space-between;
      }
    }

    &__trouble-link {
      float: left;
      margin-top: 16px;
    }
  }

  &__description {
    font-size: 12px;
    color: grey;
    margin-top: 0;
  }

  &__persist-state {
    margin-top: 16px;

    &__label {
      margin: 0 !important;
      margin-left: -12px !important;
    }
  }

  &__loading {
    position: absolute;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
  }
}
