.simple-message {
  &__form {
    width: 100%;
    padding: 0px 20px !important;
    box-sizing: border-box;

    &__name {
      width: 40%;
    }

    &__message {
      margin-top: 20px !important;
    }

    &__buttons {
      display: flex;
      margin-top: 40px;
    }

    &__button {
      &--send {
        margin-left: 15px !important;
      }
    }
  }
}