.search-results {
  flex-grow: 1;
  min-width: 0; // needed so that content doesn't overflow parent

  &__item {
    margin-bottom: 8px;

    &--skeleton {
      width: 100%;
      margin: 10px;
      margin-bottom: 30px;
    }
  }
}