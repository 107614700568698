.user-card {
  &__card-image {
    border-radius: 10px;
  }

  &__generic-card-wrapper {
    width: 100%;
  }

  &__provisory-change-icon {
    position: absolute;
    z-index: 1;
    font-size: 100px !important;
    opacity: 0.8;
  }

  &__upload-feedback {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}