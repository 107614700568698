.content-row {
  display: flex;

  &__info {
    margin-left: 20px;

    &--value {
      line-height: 24px;
    }

    &--extra {
      margin-top: 2px;
      font-size: 0.8rem;
      color: gray;
    }

    &--category {
      margin-top: 2px;
      font-size: 0.8rem;
      color: gray;
    }
  }
}