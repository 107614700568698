@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.event-gallery {
  display: flex;
  flex-direction: column;
  min-height: 80vh;

  &__main-info {
    position: relative;
    margin-left: 15px;
    margin-top: 16px;
    margin-bottom: 12px;

    &__title {
      margin-bottom: 20px;
    }

    &__info-list {
      color: gray;
      margin-bottom: 15px;
    }


    &__event {
      display: flex;
      color: gray;
      margin: 16px 0;
      font-size: .9rem;
      width: fit-content;
    }

    &__no-event {
      cursor: pointer;
    }

    &__no-event-helper {
      padding: 8px;

      &__title {
        margin-top: 0;
        margin-bottom: 8px;
      }
    }

    &__actions {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      position: relative;
      float: right;
      gap: 12px;
      margin-top: 20px;
      margin-right: 12px;
      align-items: center;

      @include media(">small-tablet") {
        position: absolute !important;
        right: 12px;
        bottom: 0;
        margin-top: 0;
        margin-right: 0;
      }

      &__loading {
        position: absolute;
        width: 12px;
        height: 12px;
        bottom: 4px;
        right: 6px;
      }

      &__new-feature-content {
        display: flex;
        gap: 8px;
        flex-direction: column;
        width: 200px;

        @include media(">small-tablet") {
          width: 240px;
        }
      }

      &__new-feature-close-button {
        position: absolute !important;
        right: 4px;
        top: 4px;
        color: gray !important;
      }
    }

    &__copy-shared-link {
      margin-left: 6px !important;
    }
  }

  &__photos-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;

    &--sticky {
      position: sticky;
      top: 0;
      z-index: 2;
      background: #fcfbf8;
      transition: all 0.4s ease-out;
    }

    &--with-header {
      top: 60px;

      @include media(">tablet") {
        top: -20px;
      }
    }

    &__photo-count-slider {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-right: auto;
      width: 168px;
      font-size: 16px;

      // Improve - do not use class directly
      & .MuiSlider-thumbSizeSmall {
        width: 8px;
        height: 8px;
      }
    }
  }

  &__marked-photos-sub-menu {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__masonry-wrapper {
    display: flex;
    justify-content: center;
  }

  &__photos-input-modal {
    &__image-list {
      margin-bottom: 48px;

      @include media(">small-tablet") {
        margin-bottom: 64px;
      }
    }

    &__total-size {
      display: flex;
      align-items: flex-end;
      margin-right: auto;
      margin-top: auto;
    }

    &__total-size-label {
      font-size: 0.8rem;
      color: gray;
      margin: 0;
    }

    &__footer {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 999;
      overflow: hidden;
  
      &__action-buttons {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        align-items: center;
        padding: 12px;
        width: 100%;
        margin: 0 19px;
        margin-bottom: 4px;
        border-radius: 8px;
        border: 1px solid #fffffff7;
        background: #fffffff7;
  
        @include media(">tablet") {
          justify-content: flex-end;
        }
      }
    }
  }

  &__snackbar {
    display: flex;
    gap: 16px;
    align-items: flex-start;
    background: white;
    border-radius: 4px;
    color: $text__secondary-color;

    &--open {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &__content {
      padding: 8px 16px;
    }

    &__more {
      align-self: center;
    }

    &__details {
      position: absolute;
      bottom: 58px; // snackbar height
      width: 100%;
      max-height: 45vh;
      overflow: scroll;
      background: white;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom: 1px solid #7575756b;
      opacity: 0;
      transform: translateY(25px);
      transform-origin: bottom;
      transition: all 300ms ease-in-out;
      z-index: -1;

      &--open {
        opacity: 1;
        transform: translateY(0px);
      }

      &__name {
        max-width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      ol {
        padding: 16px;
        margin: 0;
      }

      li {
        list-style: none;
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

// used SelectionArea to render the selection area
.selection-area {
  background: rgba(46, 115, 252, 0.11);
  border: 2px solid rgba(98, 155, 255, 0.81);
  border-radius: 0.1em;
}