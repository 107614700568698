.review-section {
  &__review {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  &__divider {
    margin-top: 30px;
    width: 40%;
    color: grey;
    opacity: 0.6;
  }

  &__form {
    margin-top: 20px;
  }
  
  &__review-rating {
    margin: 5px;
  }

  &__review-input {
    width: 100%;
  }

  &__review-submit {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
}