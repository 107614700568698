@import "styles/theme/colors.scss";

.calendar-row-mobile {
  &__day {
    position: relative;
    height: 40px;
    box-sizing: border-box;
    vertical-align: top;
    text-align: center;
    cursor: pointer;

    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.01071em;

    &--adjacent-month {
      color: rgba(0, 0, 0, 0.4);
    }

    &__label {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.72em;

      &--is-today {
        color: white;
        width: 1.72em;
        text-align: center;
        line-height: 1.72;
        margin-left: auto;
        margin-right: auto;
        border-radius: 50%;
        background: $primary-color;
      }

      &--selected {
        width: 1.72em;
        text-align: center;
        line-height: 1.72;
        margin-left: auto;
        margin-right: auto;
        border-radius: 50%;
        background: $secondary-color;
      }
    }

    &__has-event {
      display: inline-block;
      background-color: rgb(200, 198, 196);
      position: absolute;
      top: 28px;
      left: 50%;
      transform: translateX(-50%);
      height: 4px;
      width: 4px;
      border-radius: 50%;
    }
  }
}
  