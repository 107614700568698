@import "styles/resources/mixins.scss";

:root {
  --card-width: 33%;
  --card-width--mobile: 50%;
}

.mansory-cards-layout {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;

  &__sizer {
    width: var(--card-width--mobile);

    @include media(">desktop") {
      width: var(--card-width);
    }
  }

  &__card {
    width: var(--card-width--mobile);
    padding: 1%;
    box-sizing: border-box;

    @include media(">desktop") {
      width: var(--card-width);
    }

    // these modifiers must have precedence over the desktop media
    @for $i from 1 through 3 {
      &--cols#{$i} {
        width: calc(var(--card-width--mobile) * #{$i});

        @include media(">desktop") {
          width: calc(var(--card-width) * #{$i});
        }
      }
    }
  }
}