:root {
  --stack-gap: 4px;
}

.stack {
  display: flex;
  gap: var(--stack-gap);

  &--vertical {
    flex-direction: column;
  }
}
