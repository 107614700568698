@import "styles/theme/colors.scss";
@import "styles/resources/mixins.scss";

:root {
  --tabsListSelectorIndex: 0;
  --tabsListSelectorMobileLeft: 5px;
  --tabsListSelectorMobileWidth: 5px;
}

.tabs-list {
  position: sticky;
  
  @include media(">tablet") {
    margin-top: 100px;
    top: 100px;
  }

  &__selector {
    position: absolute;
    width: 30%;
    height: 1px;
    bottom: -5px;
    border: 0;
    border-bottom: 2px solid $primary-color;
    transition: all 0.3s ease-out;
    transform: translateX(var(--tabsListSelectorMobileLeft));
    width: var(--tabsListSelectorMobileWidth);
  
    @include media(">tablet") {
      width: 1px;
      height: 20px;
      left: 50px;
      bottom: unset;
      border-bottom: 0;
      border-left: 2px solid $primary-color;
      transform: translateY(calc(var(--tabsListSelectorIndex) * 36px));
    }
  }

  &__list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    justify-content: space-around;
    
    @include media(">tablet") {
      display: block;
      margin-left: 65px;
    }
  }

  &__item {
    color: gray;
    cursor: pointer;
    line-height: 24px; // necessary for tabs-list__selector work correctly

    &--selected {
      color: black;
    }

    &--hidden {
      visibility: hidden;
    }

    &:not(:first-child) {
      margin-left: 12px;
    }

    @include media(">tablet") {
      margin-top: 12px;

      &:not(:first-child) {
        margin-left: 0;
      }
    }
  }
}

