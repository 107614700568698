* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
	font-family: 'League Spartan';
  color: #1e1e1e;
}

body {
  margin: 0;
  font-family: 'Roboto';
	font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  background: transparent;
  border: 0;
}
