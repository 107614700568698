@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.home {
  @include media(">tablet") {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__box {
    padding: 0 12px;
    margin: 16px 0;
    display: flex;
    justify-content: center;

    @include media(">desktop") {
      padding: 0 32px;
    }

    &--fullscreen {
      width: 100%;
      padding: 0;
    }
  }
}