@import "styles/resources/mixins.scss";

.review {
  position: relative;

  &__reply {
    position: relative;
    margin-left: 24px;
    margin-top: 20px;

    @include media(">tablet") {
      margin-left: 40px;
    }
  }

  &__upload-feedback {
    position: absolute;
    left: 0px;
    top: 0px;
  }
}