@import "styles/resources/mixins.scss";

.icon-picker {
  &__list {
    border-radius: 5px;
  }

  &__list-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;

    @include media(">tablet") {
      padding: 0 80px;
    }  
  }

  &__list-item {
    cursor: pointer;

    &:hover {
      transform: scale(1.3);
    }
  }
}