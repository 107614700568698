.information-section {
  &__information {
    margin-left: 12px;
  }

  &__price-label {
    margin: 16px 0 8px 0;
  }

  &__price {
    margin-left: 24px;
  }

  &__faqs {
    margin-left: 4px;
    margin-top: 24px;
  }
}