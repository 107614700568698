@import "styles/resources/mixins.scss";

:root {
  --tabsListSelectorIndex: 0;
  --tabsListSelectorMobileLeft: 5px;
  --tabsListSelectorMobileWidth: 5px;
}

.react-joyride__tooltip {
  white-space: pre-line;
}

.user-details {
  &__header {
    margin-top: 20px;
  }

  &__tabs-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 10px;

    @include media(">tablet") {
      margin-top: 50px;
    }
  }

  &__tabs-options {
    margin-right: 16px;

    @include media(">tablet") {
      margin-right: 50px;
    }

    &__item:first-child {
      margin-right: 5px !important;
    }
  }

  &__tabs {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include media(">tablet") {
      flex-direction: row;
    }
  }

  &__edit-options {
    position: sticky;
    bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;

    :nth-child(2) {
      margin-left: 25px;

      @include media(">tablet") {
        margin-left: 160px;
      }
    }

    &__content {
      padding: 15px 20px !important;

      @include media(">tablet") {
        padding: 24px 40px !important;
      }
    }
  }

  &__side-menu {
    margin: 10px 0 10px 0;
    width: 100%;

    @include media(">tablet") {
      width: 15%;
    }
  }

  &__dragable-card {
    &__card {
      width: 50%;
      padding: 5px;
      box-sizing: border-box;

      @include media(">tablet") {
        padding: 10px;
      }

      @include media(">desktop") {
        width: 33%;

        @for $i from 2 through 3 {
          &--cols#{$i} {
            width: 33% * $i;
          }
        }
      }
    }

    &__editing-card {
      box-sizing: border-box;
      cursor: pointer;

      &:nth-child(2n) {
        animation: shake 0.5s;
        transform-origin: 50% 10%;
        animation-direction: alternate;
        animation-iteration-count: infinite;
      }

      &:nth-child(2n-1) {
        animation: shake2 0.5s;
        transform-origin: 30% 5%;
        animation-direction: alternate;
        animation-iteration-count: infinite;
      }

      &--selected {
        animation: none !important;
        animation-direction: unset !important;
        border-radius: 10px;
        border: 2px solid red;
      }
      &--final-dest {
        animation: none !important;
        animation-direction: unset !important;
        border-radius: 10px;
        border: 2px solid green;
      }
    }
  }

  &__packs-input-modal {
    background-color: rgb(242, 244, 245);

    &__header {
      background-color: rgb(242, 244, 245);
    }
  }
}

@keyframes shake {
  0% {
    transform: rotate(-0.2deg);
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(0.4deg);
    animation-timing-function: ease-out;
  }
}

@keyframes shake2 {
  0% {
    transform: rotate(0.2deg);
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(-0.4deg);
    animation-timing-function: ease-out;
  }
}
