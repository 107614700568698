.galleries {
  min-height: 600px;
  position: relative;

  &__subtitle {
    margin: 16px 0;
  }

  &__hidden-packs-title {
    margin-top: 40px;
  }

  &__hidden-packs {
    margin-top: 20px !important;
  }
}