@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.home-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 40px 16px;
  background-color: $background__primary-color;
  border-radius: 24px;
  width: 95vw;
  min-height: 80vh;
  overflow: hidden;

  &--secondary-color {
    background-color: $background__secondary-color;
  }

  @include media(">tablet") {
    padding: 40px 40px;
  }

  @include media(">desktop") {
    flex-direction: row;
    padding: 80px 80px;
    width: 95vw;
    max-width: 1900px;
  }

  &__content-wrapper {
    width: 100%;

    @include media(">tablet") {
      margin-top: 40px;
    }

    @include media(">desktop") {
      margin-top: 80px;
    }

    @include media(">bigScreen") {
      margin-top: 140px;
      width: 100%;
    }
  }

  &__content {
    max-width: 400px;
    margin-left: 10%;

    &__title {
      margin-bottom: 46px;

      &--with-subtitle {
        margin-bottom: 40px;
      }
    }

    &__subtitle {
      margin-bottom: 36px;
    }

    &__text {
      margin-bottom: 44px;
    }
  }

  &__image {
    width: 85%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 16px;
    float: right;
    margin-left: auto;
    margin-right: 28px;
    margin-top: 24px; // safe margin in case space-around is too short

    @include media(">tablet") {
      width: 80%;
    }

    @include media(">desktop") {
      margin: unset;
      margin-left: 28px;
      width: 100%;
    }

    @include media(">bigScreen") {
      margin-top: 80px
    }

    &--cutted {
      margin-right: -58px;

      @include media(">desktop") {
        margin-right: -100px;
      }
    }

    img {
      margin: 0;
    }
  }
}
