@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.calendar {
  min-height: 600px;

  &__header {
    display: flex;
    margin-top: 16px;

    &__current-month {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 120px;
    }

    &__add-event {
      margin-left: auto !important;
      margin-right: 8px !important;
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    margin-top: 16px;

    // add space between table header and body
    tbody:before {
      content:"@";
      display:block;
      line-height:10px;
      text-indent:-99999px;
    }
  }

  &__table-header {
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }

  &__events {
    border-top: 1px solid lightgray;
    margin: 10px;
  }

  &__event {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    min-height: 60px;
    border-bottom: 1px solid lightgray;

    &__date {
      display: flex;
      flex-direction: column;
      padding: 0 8px;
    }

    &__data {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
    }

    &__title {
      margin-bottom: 8px;
    }

    &__address {
      margin: 0;
      margin-bottom: 8px;
      font-weight: normal;
    }
  }
}