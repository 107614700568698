.upload-feedback {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__uploaded-icon {
    background-color: transparent !important;
    box-shadow: none !important;
    transform: scale(0);
    transition: transform 0.3s cubic-bezier(0.08, 0.8, 1, 1) !important;
  
    &--uploaded {
      transform: scale(1);
      background-color: green !important;
      box-shadow: inherit !important;
    }
  
    &--uploaded-done {
      background-color: green !important;
      transform: scale(0);
    }
  }

  &__progress {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    color: green !important;
  }

  &__progress-text {
    position: absolute;
    font-size: 0.7rem;
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.7rem;
    letter-spacing: 0.03333em;
  }
}
