@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.account-settings {
  display: flex;
  flex-direction: column;

  &__section {
    margin-top: 60px;

    &:first-child {
      margin: 0px;
    }
  }

  &__title {
    margin-bottom: 16px;
  }

  &__account-data-subtitle {
    margin-top: 16px;
  }

  &__username {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 500px;
    margin-top: 20px;

    &__text-field {
      width: 100%;
    }

    &__is-available-icon,
    &__loading {
      position: absolute;
      right: 10px;
    }

    &__is-available-icon {
      color: $primary-color;
    }
  }

  &__username-alert {
    margin-top: 8px !important;
    max-width: 500px;
  }

  &__email {
    position: relative;
    margin-top: 20px;

    display: flex;
    align-items: center;
    gap: 8px;

    &__input {
      width: 100%;
      max-width: 500px;
    }

    &__verified-ticker {
      position: relative;
      width: 30px;
      height: 30px;
      background-color: $primary-color;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.2s ease transform, 0.2s ease background-color;
      overflow: hidden;
      z-index: 1;
      flex-shrink: 0;
  
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin: 0 auto;
        background-color: #fff;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
    
      &:active{
        transform: scale(0.9);
      }
  
      &__tick-mark {
        position: absolute;
        top: -1px;
        right: 0;
        left: 0;
        width: 8px;
        height: 8px;
        margin: 0 auto;
        margin-left: 14px;
        transform: rotateZ(-40deg);
    
        &:before, &:after {
          content: "";
          position: absolute;
          border-radius: 2px;
          background-color: $primary-color;
        }
    
        &:before {
          left: -13px;
          bottom: -6px;
          width: 4px;
          height: 10px;
        }
    
        &:after {
          left: -13px;
          bottom: -7px;
          width: 18px;    
          height: 4px;
        }
      }
    }
  }

  &__action-button {
    position: relative;
    margin-top: 30px;
    width: fit-content;
  }

  &__action-button-loading {
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
  }

  &__alert {
    position: absolute;
    margin-top: 20px;
  }

  &__user-type {
    max-width: 600px;
    margin-top: 20px;
  }

  &__plan-badge {
    display: inline-block;
    margin: 16px 0;
    margin-right: 16px;
  }

  &__edit-plan {
    display: block;
  }

  &__expire-label {
    margin-bottom: 16px;
  }

  &__plan-period-picker {
    gap: 8px;
  }

  &__plan-card-list {
    margin-top: 20px;
    padding: 0 30px;

    @include media(">tablet") {
      padding: 0;
    }
  }

  &__plans-more-info {
    margin-top: 30px !important;
  }
}