@import "styles/utils/hide-scrollbar.scss";

.search-results {
  &__header {
    display: flex;
    margin-bottom: 30px;
    overflow: scroll;

    &__search-group-list {
      @include hideScrollbar;

      overflow: scroll;
      flex-wrap: nowrap;
    }

    &__group-separator {
      display: flex;
      align-items: center;
      height: 100%;
      font-size: 23px;
      line-height: 24px;
      color: gray;
    }
  }

  &__content-row {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }
}