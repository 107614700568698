@import "styles/resources/mixins.scss";

.info-input {
  &__description {
    width: 100%;
  }

  &__price {
    margin-top: 24px;
    margin-bottom: 4px;

    &__title {
      margin-bottom: 8px;
    }
  }

  &__quick-info-title {
    margin-bottom: 16px;
  }

  &__quick-info-wrapper {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  &__quick-info {
    &__wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    &:not(:first-child) {
      margin-top: 10px;
    }

    &__icon {
      padding: 10px;
      margin-top: 10px;
      border: 1px solid lightgray;
      cursor: pointer;
      width: 2.5rem !important;
      height: 2.5rem !important;
      font-size: 1.2rem !important;
    }

    &__main-content {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      flex-grow: 1;
    }

    &__title {
      width: 100%;
      min-width: 200px;
      
      @include media(">tablet") {
        width: 40%;
      }  
    }

    &__description {
      margin-top: 15px !important;
      min-height: 60px;

      div {
        height: 100%;
      }
    }

    &__options {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 10px;
    }
  }
}