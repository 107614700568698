@import "styles/theme/colors.scss";

.badge {
  display: flex;
  padding: 8px 12px;
  border-radius: 16px;
  background-color: $primary-color;
  align-items: center;

  &--small {
    padding: 4px 8px; 
  }

  &--outlined {
    background-color: transparent;
    border: 1px solid $text__primary-color;
  }
}
