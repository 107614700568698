@import "styles/theme/colors.scss";
@import "styles/resources/mixins.scss";

.carousel {
  &__title {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    z-index: z-index('carousel-title');
    color: lightgray;
    font-size: 16px;
    line-height: 20px;
    font-weight: 800;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    opacity: 0;
    transition: opacity 0.3s ease-in;
    text-shadow: 0px 0px 2px black;
        
    @include media(">small-tablet") {
      margin-top: 0;
    }

    // check if user's primary input mechanism can hover over elements
    @media (hover: hover) {
      opacity: 1;
    }
    
    &--carousel-ui-enabled {
      opacity: 1;
      transition: opacity 0.3s ease-out;
    }
  }

  &__close-btn {
    position: fixed !important;
    top: 20px;
    right: 30px;
    z-index: z-index('carousel-close-btn');
    color: white !important;
    opacity: 0;
    transition: opacity 0.3s ease-in !important;

    // check if user's primary input mechanism can hover over elements
    @media (hover: hover) {
      opacity: 1;
    }

    &--carousel-ui-enabled {
      opacity: 1;
      transition: opacity 0.3s ease-out !important;
    }
  }

  &__slider {
    position: fixed;
    top: 0;
    left: 0;
    z-index: z-index('carousel-slider');
    display: flex;
    flex-direction: row;
    height: 100dvh;
    width: 100vw;
    background: black;

    &__arrow-icon-wrapper {
      position: absolute;
      height: 100%;
      display: flex;
      align-items: center;
      z-index: z-index('carousel-arrow');
      margin: 0 20px;

      &--left {
        left: 0;
      }

      &--right {
        right: 0;
      }
    }

    &__arrow-icon {
      color: white !important;
    }
  }

  &__slide-item {
    height: 100%;
    background-color: black;
    width: 100vw;
    flex-grow: 1;
    flex-shrink: 0;
    transition: transform 300ms ease-in-out;
  }

  &__slide {
    height: calc(100vh - 40px);
    background-color: black !important;
    margin-top: 40px;

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      max-height: 100%;
      height: 100%;
      width: 100%;
      object-fit: contain;
      user-select: none;

      &--thumb {
        object-fit: cover;
        border-radius: 4px;
      }

      &--selected {
        border: 1px solid $primary-color;
      }

      &--non-selectable {
        // prevent Safari long press
        -webkit-touch-callout: none;
      }

      &--blurred {
        img {
          filter: blur(4px);
        }
      }  
    }
  }

  &__swiper-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    background: black;
    z-index: z-index('carousel-swiper');
  }

  &__swiper {
    position: relative;
    height: calc(90% - 8px);
    transition: all 250ms ease-in-out;

    @media screen and (orientation:landscape) {
      height: calc(88% - 8px);
    }

    &--fullscreen {
      height: 100%;
    }

    &__action-buttons {
      position: absolute;
      right: 16px;
      bottom: 12px;
      display: flex;
      gap: 8px;
      opacity: 0;
      transition: opacity 0.3s ease-in;
      z-index: z-index('carousel-action-btns');

      &--carousel-ui-enabled {
        transition: opacity 0.3s ease-out;
        opacity: 1;
      }
    }

    &__action-button {
      color: white !important;
    }

    .swiper-button-next, .swiper-button-prev{
      height: 100%;
      top: 0;
      width: 8%;
    }
  }

  &__swiper-slider {
    height: 100%;
  }

  &__swiper-thumbs {
    position: absolute;
    bottom: 4px;
    width: 100%;
    height: 10%;
    padding: 0 4px;
    opacity: 1;
    transition: all 0.3s ease-in;
    transform: translateY(0);

    @media screen and (orientation:landscape) {
      height: 12%;
    }

    &--carousel-ui-disabled {
      transition: all 0.3s ease-out;
      opacity: 0;
      transform: translateY(100px);
    }
  }
}