@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.user-pack {
  position: relative;
  width: 100%;

  &--with-min-height {
    min-height: 300px;
  }

  &__image {
    border-radius: 10px;
    object-fit: cover;
  }

  &__info-wrapper {
    width: 100%;
    z-index: 1;
  }

  &__info {        
    margin: 8px;

    &__main-info {
      display: flex;
      justify-content: space-between;
    }

    &__title {
      margin: 0;

      // limit to 2 lines
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 16px;
    }

    &__price {
      font-size: 0.8rem;
      white-space: nowrap;
      margin: 0;

      &__value {
        font-size: 1rem; 
      }
    }

    &__description {
      display: none;
      justify-content: center;
      align-items: start;
      margin-top: 8px;
      opacity: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 0.9rem;
      color: $text__secondary-color;

      @include media(">small-tablet") {
        display: -webkit-box;
      }
    }
  }

  &--clickable {
    cursor: pointer;
  }
}