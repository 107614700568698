.button {
  border-radius: 8px;

  &--x-large {
    padding: 10px 24px !important;
  }
}

.icon-button {
  &__bottom-text {
    margin-top: 16px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  
    &__text {
      margin-top: 6px;
      font-size: 0.7rem;
      text-align: center;
    }
  }
}