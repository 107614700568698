:root {
  --image-height: 40px;
  --image-width: 40px;
}

.sized-image {
  position: relative;
  width: var(--image-width);
  padding-bottom: var(--image-height);

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}