.share-link {
  &__share-wrapper {
    display: flex;
    align-items: center;
  }
  
  &__share-button {
    margin: 0 !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
    
    &:hover {
      background-color: rgba(0, 0, 0, 0.144) !important;
    }
  }
}
