@import "styles/theme/colors.scss";

.loading-component {
  color: $button__primary-color !important;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3c3b3b14;
    border-radius: 50%;
  }

  &__value-label {
    position: absolute;
    font-size: 0.6rem;
    color: white;
  }
}

.loading-component-info {
  display: flex;
  gap: 12px;
  align-items: center;

  &--linear {
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 4px;
  }

  &__label {
    font-size: 12px;
    color: white;

    &--linear {
      display: flex;
      justify-content: flex-end;
      margin-right: 8px;
      margin-bottom: 4px;
    }
  }
}

.backdrop-loading {
  z-index: 100 !important;
}