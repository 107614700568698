.breadcrumb {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  & * {
    margin: 0;
    text-decoration: none;
  }

  & >:not(:first-child) {
    margin-left: 10px;
    margin-bottom: 4px;
  }

  & >:last-child {
    color: gray;
  }

  // guarantee first child is always in correct color, even if list has only one element
  & >:first-child {
    color: initial;
  }
}
