@import "styles/resources/mixins.scss";

.title-image-side-by-side {
  display: flex;
  flex-direction: column;

  @include media(">tablet") {
    flex-direction: row;
  }

  &__titles {
    padding: 5px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;

    @include media(">tablet") {
      padding: 30px;
    }
  }

  &__image {
    width: 100%;
    border-radius: 12px;

    @include media(">tablet") {
      width: 50%;
      min-width: 50%;
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: normal;
    padding: 0 10px;

    @include media(">tablet") {
      padding: 0;
      font-size: 3rem;
    }
  }

  &__sub-title {
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: normal;
    padding: 0 10px;

    @include media(">tablet") {
      padding: 0;
      font-size: 1.5rem;
    }
  }

  &__action-button {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
}
