@import "styles/resources/mixins.scss";

.generic-add-button-list {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: -10px;
  gap: 10px;
}

.generic-add-button {
  position: absolute;
  display: none;

  @include media(">tablet") {
    display: inline;
    right: 20px;
    top: 0px;
  }

  &--inline {
    position: relative; 
    right: unset;
    top: unset;
  }

  &--mobile {
    display: inline;
    right: 20px;
    top: -10px;

    @include media(">tablet") {
      display: none;
    }
  }

  &--no-position {
    right: 0;
    top: 0;
  }
}