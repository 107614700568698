@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.pack-details {
  &__title {
    margin: 16px 0;
    margin-left: 24px;
   
    @include media(">tablet") {
      margin-left: 50px;
    }
  }

  &__price {
    margin-left: 24px;
    margin-top: -8px;
    margin-bottom: 20px;
    font-size: 0.9rem;
    color: $text__secondary-color;
   
    @include media(">tablet") {
      margin-left: 50px;
    }
  }

  &__main-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    
    @include media(">tablet") {
      justify-content: space-between;
      flex-wrap: nowrap;
      margin: 0 50px;
    }

    &__image-waterfall {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex-shrink: 0;
      align-items: center;

      @include media(">tablet") {
        width: 60%;
      }
    }

    &__image-wrapper {
      width: 100%;
      cursor: pointer;

      &:not(:first-child) {
        margin-top: 5px;
        @include media(">tablet") {
          margin-top: 20px;
        }
      }
    }

    &__more-images-btn {
      margin-top: 20px;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;

      @include media(">tablet") {
        border-radius: 16px;
      }
    }

    &__side-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      
      @include media(">tablet") {
        position: sticky;
        top: 20px;
        margin-left: 70px;
        height: min-content;
        width: 40%;
      }
    }

    &__side {
      display: flex;
      flex-direction: column;
      padding: 20px;
      border-radius: 16px;
      height: 100%;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .15);
      min-height: 300px;
    }  

    &__side-bottom {
      display: none;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      cursor: pointer;

      svg {
        transition: transform 0.4s ease-out, color 0.4s ease-out;
        transform: translateY(0px);
        fill: #bdbdbd;
      }

      &:hover {
        color: grey;
        
        svg {
          transition: transform 0.4s ease-out, color 0.4s ease-out;
          transform: translateY(8px);
        }
      }

      @include media(">tablet") {
        display: flex;
      }

      &--mobile {
        display: flex;
        position: sticky;
        bottom: 0px;
        margin: 0;
        justify-content: center;
        width: 100%;
        height: 86px;
        background: $app-background-color;

        @include media(">tablet") {
          display: none;
        }
      }
    }

    &__message-me {
      width: 100%;
      margin-top: auto;
      padding-top: 16px; // minimum margin top without removing the auto top margin behaviour

      button {
        width: 100%;
      }
    }

    &__categories {
      margin-top: 20px;
    }

    &__description {
      text-align: justify;
      margin-top: 16px;
      white-space: pre-line;
    }
  }

  &__tabs-wrapper {
    margin-top: 20px;
    padding: 0;
    
    @include media(">tablet") {
      margin-top: 20px;
      padding: 0 50px;
    }
  }

  &__tabs {
    &__content {
      white-space: pre-line;

      &:not(:first-of-type) {
        margin-top: 60px;
      }
    }
  }
}