@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.container {
  padding: 0 10% 10% 10%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 10px;

  @include media(">tablet") {
    padding: 0 12% 12%;
  }

  &__post-page {
    display: flex;
    flex-direction: column;
  }

  &__posts {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: 400px;
    grid-gap: 25px;
    padding-bottom: 4%;
  }
}
