@import "styles/resources/mixins.scss";

.photo-picker {
  &__content {
    display: flex;
    flex-direction: column;
  
    @include media(">desktop") {
      align-items: flex-end;
      flex-direction: row;
    }
  }

  &__input {
    position: relative;
    width: 160px;
    height: 160px;
    left: 50%;
    transform: translateX(-50%);

    @include media(">small-tablet") {
      left: 0;
      transform: translateX(0);
      margin-right: 40px;
    }
  }

  &__default-images-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }

  &__default-images-label {
    margin-top: 25px;

    @include media(">desktop") {
      margin-top: 15px;
    }
  }

  &__default-images-wrapper {
    overflow-x: scroll;
  }

  &__default-images {
    display: flex;
    margin-top: 15px;
    justify-content: center;
    width: fit-content;

    @include media(">desktop") {
      justify-content: start;
    }

    &__image {
      cursor: pointer;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      margin-right: 20px;
      
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__alert {
    margin-top: 50px;
  }
}