@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.images-input {
  &__list-item-wrapper {
    width: 50%;

    @include media(">tablet") {
      width: 100%;
      min-height: auto;
    }
  }

  &__menu-wrapper {
    width: 100%;

    @include media(">tablet") {
      min-height: auto;
    }
  }

  &__list-item {
    position: relative;
    display: flex;
    width: 300px;
    max-width: 320px;
    align-items: center;
    min-height: 140px;
    overflow: hidden;

    @include media(">small-tablet") {
      min-height: 200px;
    }

    @include media(">tablet") {
      min-height: 220px;
    }

    &--add-image {
      display: flex;
      border: 1px solid $grey;;
      border-radius: 16px;
      transition: transform 0.3s ease-out;
      transform: scale(1);
      width: 100%;
      height: 100%;

      &--focused {
        transform: scaleY(1.03);
        border-color: $dark-grey;
      }

      &:hover {
        transform: scaleY(1.03);
        border-color: $dark-grey;
      }
    }

    &__image {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
      opacity: 1;

      &--dragging {
        opacity: 0.5;
      }
    }

    &__loading {
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
    }

    &__main-image {
      position: absolute;
      top: 10px;
      left: 10px;
      color: $primary-color;
    }

    &__edit-wrapper {
      position: absolute;
      display: flex;
      height: 100%;
      bottom: 0;
      width: 100%;
      justify-content: space-evenly;
      align-items: center;
      background-image: linear-gradient(to bottom, transparent, white);
      opacity: 0;
      transform: translateY(100px);
      transition: all 0.3s ease-out;

      @include media(">tablet") {
        height: 60px;
      }

      &--show {
        transform: translateY(0);
        opacity: 1;
      }
    }

    &__is-favorite {
      position: absolute;
      top: 5px;
      right: 5px;
      transform: scale(1);
      transition: all 0.3s ease-out !important;

      @include media(">tablet") {
        right: 25px;
      }

      &--hidden {
        transform: scale(0);
      }
    }
  }

  &__input {
    display: flex;
    width: 300px;
    height: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
  }
}