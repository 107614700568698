.icon-label {
  align-items: center;
  display: flex;

  &__icon {
    margin-right: 2px;
    margin-left: 2px;
    
    &--no-left-margin {
      margin-left: 0;
    }
  }
  
  &--inverted {
    flex-direction: row-reverse;
  }
}