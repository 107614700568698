@import "styles/theme/colors.scss";
@import "styles/theme/box-styles.scss";
@import "styles/resources/mixins.scss";

.search-result-item {
  display: flex;
  padding: 15px 15px;
  border-radius: 10px;
  flex-direction: column;

  @include media(">tablet") {
    flex-direction: row;
  }

  &__img-wrapper {
    width: 100%;
    flex-shrink: 0;

    @include media(">tablet") {
      width: 300px;
    }

    img {
      border-radius: 12px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    flex-grow: 1;
    min-width: 0; // needed so that content doesn't overflow parent

    @include media(">tablet") {
      margin-left: 20px;
    }

    &__title {
      margin: 0;
      margin-top: 20px;

      @include media(">tablet") {
        margin: 0;
      }
    }

    &__pack-info {
      margin-top: 10px;
    }

    &__pack-categories {
      margin-top: 10px;
    }

    &__price {
      margin-top: 10px;
    }

    &__description {
      margin: 10px 0;
      white-space: pre-line;

      // limit to 4 lines
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    &__user-info {
      margin-top: auto;
    }
  }
}