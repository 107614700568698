@import "styles/theme/colors.scss";
@import "styles/resources/mixins.scss";

.post {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 3%;

  &__metadata {
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;
    left: unset;
    width: auto;
    margin-bottom: 0;

    @include media(">tablet") {
      position: fixed;
      left: 12%;
      width: 10%;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }

    &__back-link {
      display: none;
      align-items: center;
      gap: 5px;
      font-weight: bold;
      margin-bottom: 0;

      &:hover {
        color: $primary-color;
      }

      @include media(">tablet") {
        display: flex;
        margin-bottom: 20px;
      }
    }

    &__author,
    &__date,
    &__time-to-read {
      display: none;
      align-items: center;
      gap: 5px;

      @include media(">tablet") {
        display: flex;
      }
    }

    &__tags-container {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      &__link {
        &:hover {
          color: black;
        }
        background-color: $primary-color;
        padding: 5px 10px;
        border-radius: 15px;
        font-size: 12px;
      }
    }
  }

  &__metadata-mobile {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;

    @include media(">tablet") {
      display: none;
    }

    &__author,
    &__date,
    &__time-to-read {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  &__content {
    @include media(">tablet") {
      margin-left: 19%;
      margin-right: 19%;
    }

    &__author,
    &__date,
    &__time-to-read {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  &__content {
    margin: 0;

    flex: 1 1 auto;
    line-height: 28px;

    @include media(">tablet") {
      margin: 0 19%;
    }

    &__image-container {
      position: relative;
    }

    &__image {
      border-radius: 10px;
      width: 100%;
      max-height: 60vh;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__markdown {
      picture {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 40px;
        img {
          border-radius: 10px;
        }
      }
    }

    h1,
    h2 {
      margin-top: 40px;
      margin-bottom: 10px;
      text-align: left;
      color: $text__primary-color;
    }

    p {
      text-align: justify;
      line-height: 2;
    }

    strong {
      font-weight: bold;
    }

    ul {
      text-align: justify;
      list-style-type: none;

      li {
        position: relative;
        &::before {
          content: "•";
          color: $text__primary-color;
          font-size: 25px;
          margin-right: 5px;
          position: absolute;
          left: -20px;
        }
      }
    }

    .iframe-wrapper {
      margin-top: 10px;
      margin-bottom: 40px;
    }
  }

  &__index {
    display: none;

    @include media(">tablet") {
      position: fixed;
      right: 12%;
      width: 10%;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      overflow-y: auto;

      &__title {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 10px;
        color: $text__primary-color;
        &:hover {
          color: $text__light-color;
        }
      }

      &__div {
        width: 100%;
        border: none;
        border-top: 1px solid;
        color: rgb(197, 197, 197);
        margin-top: 0px;
        margin-bottom: 0px;
      }

      &__list {
        list-style-type: none;
        padding-left: 0;
        margin-top: 10px;
        gap: 7px;

        &__link:hover {
          color: $text__primary-color;
        }
        &__link {
          &.level-1 {
            padding-left: 0px;
          }
          &.level-2 {
            padding-left: 10px;
          }
          &.level-3 {
            padding-left: 20px;
          }
          &.level-4 {
            padding-left: 30px;
          }
        }
      }
    }
  }
}
