.rating {
  align-items: center;
  display: flex;

  &__raw-value {
    margin-left: 10px;
    color: #737272;
  }

  &__count {
    margin-left: 5px;
  }
}
