:root {
  --grid-item-spacing: 0px;
}

.grid {
  &__item {
    &--with-spacing {
      padding-left: var(--grid-item-spacing);
      padding-right: var(--grid-item-spacing);

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}