.user-info-input {
  display: flex;
  flex-direction: column;

  &__text-field {
    width: 100%;
  }

  &__input-section {
    margin-top: 30px;
  }

  &__subtitle {
    margin-bottom: 16px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px !important;

    &__button {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}