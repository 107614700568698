.comment-section {
  &__comment {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  &__sub-comment {
    margin-left: 40px;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  &__comment-input {
    margin-top: 20px !important;
    width: 100%;
  }

  &__comment-submit {
    margin-top: 10px !important;
  }
}