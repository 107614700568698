@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

* {
  color: inherit;
}

body {
  font-size: 16px;

  @include media(">bigScreen") {
    font-size: 20px;
  }

  // src: https://css-tricks.com/snippets/css/retina-display-media-query/
  @media 
  (-webkit-min-device-pixel-ratio: 2), 
  (min-resolution: 192dpi) { 
    font-size: 16px;
  }
}

.App {
  text-align: center;
  color: $text__primary-color;
}

.App-logo {
  height: 100px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-content {
  padding-top: 60px;
  background-color: $app-background-color;
  flex-grow: 1;

  &--no-header {
    padding-top: 0;
  }

  &--no-footer {
    position: relative;
    padding-bottom: 68px;

    @include media(">tablet") {
      padding-bottom: 0px;
      // keep all backoffice fixed besides content
      position: fixed;
      width: 100%;
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
