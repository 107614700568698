.downloading-icon {
  position: relative;
  margin: 8px;
  background: #ffffff35;
  border-radius: 50%;

  &__loading {
    position: absolute;
    right: 0px;
    top: 0px;
  }
}
