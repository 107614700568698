@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.professionals-join-us {
  text-align: center;
  padding: 50px 0;

  &__title {
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: normal;
    margin-top: 0;

    @include media(">desktop") {
      font-size: 3rem;
    }
  }
}