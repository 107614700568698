@import "styles/theme/colors.scss";

:root {
  --image-height: 40px;
}

.contained-image {
  position: relative;
  width: 100%;
  padding-bottom: var(--image-height);
  border-radius: 16px;
  background-color: $background__primary-color;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}