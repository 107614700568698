// palette: https://coolors.co/palette/ccd5ae-e9edc9-fefae0-faedcd-d4a373
// A11Y checker https://webaim.org/resources/contrastchecker/?fcolor=C1CE96&bcolor=FFFFFF
// Automatic --dark & --light variations: https://mui.com/material-ui/customization/color/

// generic colors
$dark-grey: #282424;
$danger-color: #d32f2f;
$light-grey: #d9d9d9;
$white: white;
$grey: rgba(0, 0, 0, 0.54);
$enhanced-grey: #474646;

// brand colors
$primary-color: #ccd6a6;
// dark and light mostly necessary for material-ui to set hover colors
$primary-color--dark: #8e9574;
$primary-color--light: #d6deb7;

$secondary-color: #F5E9D3;
$secondary-color--dark: #aba393;
$secondary-color--light: #fbf4e9;

$third-color: #D4A373;
$third-color--dark: #947250;
$third-color--light: #dcb58f;

// background colors
$app-background-color: #fcfbf8;
$background__primary-color: #ccd6a6; // This background is based on the primary color
$background__secondary-color: #F5E9D3;
$background__dark-color: #212320;
$background__gray-color: #f1f1f1;

// text colors
$text__default-color: $dark-grey; // text color used by default
$text__primary-color: #6A7B23; // text color based on primary color - this is the first colors that matches the a11y
$text__secondary-color: #595959;
$text__light-color: $primary-color;
$text__disabled-color: $light-grey;
$text__grey-color: #656d76;
$text__enhanced-grey-color: $enhanced-grey; // a bit more facy gray, should be used in secondary titles / subtitles
$text__white-color: $white;

// button colors
$button__default-color: $dark-grey;
$button__primary-color: #6A7B23;
$button__secondary-color: $secondary-color;
$button__light-color: $primary-color;
$button__danger-color: $danger-color;


// borders
$light-border: #edebeb;

// exported to be used in JS
:export {
  // new structure
  // generic colors
  dangerColor: $danger-color;
  lightGrey: $light-grey;
  grey: $grey;
  darkGrey: $dark-grey;
  // primary color
  primaryColor: $primary-color;
  primaryColorDark: $primary-color--dark;
  primaryColorLight: $primary-color--light;
  // secondary color
  secondaryColor: $secondary-color;
  secondaryColorDark: $secondary-color--dark;
  secondaryColorLight: $secondary-color--light;
  // third color
  thirdColor: $third-color;
  thirdColorDark: $third-color--dark;
  thirdColorLight: $third-color--light;
  // background colors
  appBackgroundColor: $app-background-color;
  backgroundPrimaryColor: $background__primary-color;
  backgroundSecondaryColor: $background__secondary-color;
  backgroundDark: $background__dark-color;
  backgroundGray: $background__gray-color;
  // text colors
  textDefaultColor: $text__default-color;
  textPrimaryColor: $text__primary-color;
  textSecondaryColor: $text__secondary-color;
  textLightColor: $text__light-color;
  textDisabledColor: $text__disabled-color;
  textGreyColor: $text__grey-color;
  textWhiteColor: $text__white-color;
  // button colors
  buttonDefaultColor: $button__default-color;
  buttonPrimaryColor: $button__primary-color;
  buttonSecondaryColor: $button__secondary-color;
  buttonLightColor: $button__light-color;
  buttonDangerColor: $button__danger-color;
}

// TODO: possible future way to have themes: https://itnext.io/css-variables-dynamic-app-themes-86c0db61cbbb