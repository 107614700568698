.mfa-table-row-cells {
  &__content-cell {
    display: flex;
    gap: 8px;

    &__content {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__header {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
    }
  }

  &__totp-cta-cell {
    display: flex;
    gap: 8px;
  }

  &__recovery-code-cta-cell {
    display: flex;
    gap: 8px;
  }
}