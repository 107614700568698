@import "styles/resources/mixins.scss";

.event {
  position: relative;
  min-height: 80vh;

  &__edit-event {
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    margin: 10px;
    gap: 8px;

    @include media(">small-tablet") {
      position: absolute;
      right: 20px;
      top: 10px;
    }
  }

  &__main-info {
    margin-top: 20px;

    &__user-circular {
      margin-left: 6px;
      margin-bottom: 8px;
    }

    &__icon-info {
      margin-bottom: 8px;
      font-size: 0.9rem;

      svg {
        width: 36px;
        margin-right: 8px;
      }
    }
  }

  &__galleries {
    &__title {
      margin-top: 40px;
      margin-bottom: 8px;
    }

    &__first-gallery {
      &__label {
        margin-bottom: 8px;
      }
    }

    &__wrapper {
      position: relative;
    }

    &__add-gallery {
      position: absolute;
      right: 20px;
      top: 0;
    }
  }
}