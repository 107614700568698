@import "styles/theme/colors.scss";

.faq {
  display: flex;
  border-bottom: 1px solid $light-grey;
  padding: 15px 0;

  &:first-child {
    border-top: 1px solid $light-grey;
  }

  &__question {
    padding-right: 10px;
    width: 40%;
    flex-shrink: 0;
  }
}