@import "styles/resources/mixins.scss";

.search {
  display: flex;
  flex-direction: column;
  margin: 0 5px;
  overflow: hidden;

  @include media(">tablet") {
    margin: 0 20px;
  }

  &__search-bar {
    width: 100%;
    margin: 50px 0 50px 0;
    align-self: center;

    @include media(">tablet") {
      width: 70%;
    }
  }

  &__search-groups-wrapper {
    display: flex;

    @include media(">tablet") {
      display: inline-block;
    }
  }

  &__search-groups {
    &__separator {
      display: flex;
      align-items: center;
      height: 100%;
      font-size: 23px;
      line-height: 24px;
      color: gray;
    }
  }

  &__main {
    display: flex;
    margin-top: 40px;
    flex-direction: column;

    @include media(">tablet") {
      flex-direction: row;
    }
  }

  &__side-options {
    min-width: 220px;

    @include media(">tablet") {
      margin-right: 30px;
    }
  }

  &__filters {
    margin-top: 15px;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
}