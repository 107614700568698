.mfa-sign-in-form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__header {
    display: flex;
    gap: 8px;
  }

  &__action-buttons {
    display: flex;
    justify-content: flex-end;
    
    &--multiple {
      justify-content: space-between;
    }
  }

  &__trouble-link {
    margin-top: 8px;
  }
}
