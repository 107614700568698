@import "styles/theme/colors.scss";

.auto-complete-input {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__clear-icon {
    position: absolute !important;
    right: 0;
    width: 25px;
    height: 25px;
    margin-right: 6px !important;

    svg {
      width: 16px;
    }
  }

  &__loading {
    position: absolute;
    right: 45px;
  }

  &__option-skeleton {
    display: flex;
    width: 100%;

    span:not(:first-child) {
      margin-left: 10px;
    }
  }

  &__paper {
    position: absolute;
    top: 56px;
    z-index: 2;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    visibility: hidden;

    &--open {
      visibility: visible;
    }
  }

  &__list {
    list-style: none;
    margin: 16px;
    padding: 0;
  }

  &__list-item {
    cursor: pointer;
    height: 30px;
    align-items: center;
    display: flex;
    padding: 5px;
    border-radius: 5px;

    &:hover {
      background-color: $background__primary-color;
    }

    &--no-hover {
      cursor: initial;

      &:hover {
        background-color: initial;
      }
    }
  }
}