@import "styles/theme/colors.scss";
@import "styles/theme/box-styles.scss";

.postCard {
  &.skeleton {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    transition: transform 0.2s;
    box-shadow: $default-box-shadow;
    background-color: white;

    .img {
      border-radius: 10px 10px 0 0;
      width: 100%;
      height: 220px;
      background-color: #ddd;
    }

    .text {
      display: flex;
      flex-direction: column;
      padding: 15px;
      align-items: center;

      .title {
        height: 20px;
        width: 70%;
        background-color: #ddd;
        margin-bottom: 10px;
        border-radius: 10px;
      }

      .tags {
        height: 20px;
        width: 25%;
        background-color: #ddd;
        border-radius: 10px;
      }
    }
    animation: skeleton-loading 1.5s infinite ease-in-out;

    @keyframes skeleton-loading {
      0%,
      100% {
        opacity: 0.6;
      }
      50% {
        opacity: 1;
      }
    }
  }
}
