@import "styles/theme/colors.scss";
@import "styles/resources/mixins.scss";

.categories {
  display: flex;
  flex-wrap: wrap;

  &__item {
    margin-right: 5px;

    &--chips {
      margin-top: 5px;

      div {
        color: black;
        background: $primary-color;
      }
    }
  }
}