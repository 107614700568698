.cover-photo-picker {
  &__image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 90px;
    cursor: pointer;
  }

  &--cover-photo {
    border: 1px solid gray;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
