@import "styles/resources/mixins.scss";

.user-details-skeleton {
  &__header {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    &__content {
      display: flex;
      flex-direction: column;
      width: 85%;
      justify-content: space-around;
      align-items: center;

      @include media(">tablet") {
        flex-direction: row;
      }

      &--text {
        width: 80%;
        height: 100px;
        margin-top: 20px;

        @include media(">tablet") {
          width: 60%;
          margin-top: 0;
        }

        @include media(">desktop") {
          width: 75%;
          margin-top: 0;
        }
      }
    }
  }

  &__main {
    display: flex;
    min-height: 400px;
    margin-top: 40px;

    &--left {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15%;
    }

    &--right {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 75%;
    }

    &__box {
      padding: 10px;
      width: 42%;

      @include media(">small-tablet") {
        width: auto;
        flex-grow: 1;
        height: 350px;
        max-width: 45%;
        min-width: 200px;
      }
    }
  }
}