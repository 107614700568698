@import "styles/theme/colors.scss";
@import "styles/resources/mixins.scss";

.user-type {
  display: flex;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    width: 50%;
    flex-grow: 1;
    border-radius: 10px;
    padding: 10px;
    margin-right: 2px;
    margin-left: 2px;
    cursor: pointer;
    transition: 0.2s ease transform;
    border: 2px solid $background__gray-color;

    @include media(">desktop") {
      padding: 30px;
      margin-right: 10px;
      margin-left: 10px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      transition: 0.2s ease transform;
      transform: translateY(-2px);

      .user-type__ticker:before {
        width: 15px;
        height: 15px;
      }

      .user-type__item__title {
        transition: 0.2s ease transform;
        transform: translateY(-6px);
        transition-delay: 0.1s;
      }

      .user-type__item__content {
        transition: 0.2s ease transform;
        transform: translateY(-6px);
        transition-delay: 0.05s;
      }
    }

    &--active {
      background-color: $background__gray-color;
    }

    &__title {
      margin-bottom: 20px;
      transition: 0.2s ease transform;
      transition-delay: 0.1s;
    }

    &__content {
      transition: 0.2s ease transform;
      transition-delay: 0.05s;
    }
  }

  &__input-wrapper {
    display: flex;
    position: relative;
    margin-top: 20px;
    width: 100%;
    height: 30px;
    justify-content: center;
  }

  &__input {
    margin: 0;
    width: 30px;
    height: 30px;
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;

    &:focus + .user-type__ticker:before {
      width: 15px;
      height: 15px;
    }

    &:checked + .user-type__ticker {
      background-color: $primary-color;
    }

    &:checked + .user-type__ticker:hover {
      transform: scale(0.9);
    }

    &:checked + .user-type__ticker:before {
      width: 0;
      height: 0;
    }

    &:checked + .user-type__ticker .user-type__ticker__tick-mark:before,
    &:checked + .user-type__ticker .user-type__ticker__tick-mark:after {
      transform: translate(0);
      opacity: 1;
    }
  }

  &__ticker {
    position: absolute;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background-color: $primary-color;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.2s ease transform, 0.2s ease background-color;
    overflow: hidden;
    z-index: 1;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      margin: 0 auto;
      background-color: #fff;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      transition: 0.2s ease width, 0.2s ease height;
    }

    &:hover:before{
      width: 15px;
      height: 15px;
    }

    &:active{
      transform: scale(0.9);
    }

    &__tick-mark {
      position: absolute;
      top: -1px;
      right: 0;
      left: 0;
      width: 8px;
      height: 8px;
      margin: 0 auto;
      margin-left: 14px;
      transform: rotateZ(-40deg);
  
      &:before, &:after {
        content: "";
        position: absolute;
        background-color: #fff;
        border-radius: 2px;
        opacity: 0;
        transition: 0.2s ease transform, 0.2s ease opacity;
      }
  
      &:before {
        left: -13px;
        bottom: -6px;
        width: 4px;
        height: 10px;
        transform: translateY(-20px)
      }
  
      &:after {
        left: -13px;
        bottom: -7px;
        width: 18px;    
        height: 4px;
        transform: translateX(30px)
      }
    }
  }
}