.configure-totp-modal {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__title {
    margin-top: 16px;
  }

  &__qr-code {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px;
  }

  &__code-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    margin: 8px 0 0 8px;
  }
}