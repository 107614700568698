@import "styles/resources/mixins.scss";

:root {
  --user-circular-input-width: 160px;
}

.user-circular-input {
  position: relative;
  width: var(--user-circular-input-width);
  height: var(--user-circular-input-width);

  &__avatar {
    display: inline-block;

    &--no-image {
      height: 100%;
      border: 2px dashed black;
      border-radius: 50%;
    }

    @include media(">tablet") {
      justify-content: flex-start;
    }
  }

  &__avatar-action {
    position: absolute;
    z-index: 1;

    &--edit {
      bottom: 5px;
      right: 12px;
    }

    &--remove {
      bottom: -13px;
      right: calc(100% / 2 - 20px);
    }

    button {
      width: 40px;
      height: 40px;
      background-color: #0000001f;
    }
  } 

  &__image-input {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  &__avatar-upload-status {
    position: absolute;
    bottom: 5px;
    right: 12px;
    z-index: 1;
  }
}
