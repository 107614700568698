@import "styles/theme/colors.scss";
@import "styles/resources/mixins.scss";

:root {
  --item-left-margin: 10px;
  --item-height: 100%;
}

.block-list {
  display: flex;
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
  height: var(--item-height);
  min-height: 20px;
  overflow: scroll;
  
  &__item {
    width: 90px;
    min-width: min-content;
    list-style: none;
    margin-right: var(--item-left-margin);
    flex-shrink: 0;
    
    &--fill {
      flex-shrink: 1;
      &:first-child {
        margin-left: var(--item-left-margin);
      }

      &:last-child {
        padding-right: var(--item-left-margin);
      }
    }

    &:last-child {
      margin-right: 0;
    }

    @include media(">tablet") {
      width: 200px;
    }
  }

  &__block {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: $background__gray-color;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.1s linear;

    &:hover {
      box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
      background-color: $background__gray-color;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }

    &__name {
      position: absolute;
      text-align: center;
      width: 100%;
      padding: 8px 0px;
    }

    &__name-background {
      position: absolute;
      width: 100%;
      height: 50%;
      background: linear-gradient(#00000000, #ffffffb0);
      border-radius: 10px;
    }
  }
}