@import "styles/resources/mixins.scss";

.watermarks-picker {
  &__empty {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 16px;

    &__button {
      margin-top: 8px;
    }
  }

  &__loading-wrapper {
    margin: 24px;
  }

  &__watermark {
    cursor: pointer;
  }

  &__add-more-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin: 16px 0;

    @include media(">small-tablet") {
      position: absolute;
    }
  }
}
