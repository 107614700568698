@import "styles/theme/colors.scss";
@import "styles/resources/mixins.scss";

.search-bar {
  position: relative;
  width: 100%;

  &__label {
    position: absolute;
    top: 50%;
    left: 15px;
    font-size: 16px;
    transform: translateY(-50%);
    transition: all 0.3s ease-out;
    pointer-events: none;

    @include media(">desktop") {
      font-size: 22px;
    }

    &--focused {
      transform: translate(-28px, -50px) scale(0.8);
      
      @include media(">tablet") {
        transform: translate(-18px, -65px) scale(0.9);
      }
    }
  }

  &__input {
    width: 100%;
    padding-top: 0px;
    line-height: 55px;
    border-radius: 10px;
    font-size: 16px;
    border: 0;
    background-color: $background__gray-color;
    padding: 0 10px;
    transition: border-radius 0.3s linear;

    &:focus {
      outline: none;
    }

    &--open {
      border-radius: 10px 10px 0 0;
      border-bottom: 2px solid #dcdbdb;
    }

    @include media(">tablet") {
      line-height: 65px;
      font-size: 22px;
    }
  }
}