.linear-progress {
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 8px;

  &__progress {
    flex-grow: 1;
  }

  &__label {
    font-size: .8rem;
    color: #303030;
  }
}