@import "styles/theme/colors.scss";

.link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  :visited {
    text-decoration: none;
  }

  :hover {
    text-decoration: none;
  }

  :active {
    text-decoration: none;
  }

  &--underlined {
    :hover {
      text-decoration: underline;
    }
  
    :active {
      text-decoration: underline;
    }
  }

  &--hover-underlined {
    text-decoration: underline;
  }

  &--disabled {
    pointer-events: none
  }

  &--colored {
    color: $text__primary-color;
  }
}