.watermarks-pin {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  &__image {
    border-radius: 16px;
  }

  &__image-wrapper {
    background: transparent;
    border: 1px solid lightgray;
  }
}