@import "styles/theme/colors.scss";
@import "styles/theme/box-styles.scss";
@import "styles/resources/mixins.scss";
@import "styles/utils/hide-scrollbar.scss";

.photographer-search-result-item {
  display: flex;
  padding: 15px 15px;
  border-radius: 10px;
  flex-direction: column;

  @include media(">tablet") {
    flex-direction: row;
    padding: 15px 30px;
  }

  &--gallery {
    flex-direction: column;
    align-items: center;
    padding: 15px 15px;
  }

  &__user-circular {
    justify-content: center;
    display: grid;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    flex-grow: 1;
    min-width: 0; // needed so that content doesn't overflow parent

    @include media(">tablet") {
      margin-top: 0;
      margin-left: 30px;
    }

    &--gallery {
      width: 100%;
      margin-top: 20px;
      margin-left: 0px;
    }

    &--skeleton {
      align-items: center;
    }

    &__title {
      font-size: 1.2rem;
      margin: 0;
      margin-bottom: 10px;
      text-align: center;

      @include media(">tablet") {
        text-align: left;
      }

      &--center {
        text-align: center;
      }
    }

    &__categories {
      @include hideScrollbar;

      margin-top: 10px;
      color: $text__secondary-color;
      overflow: scroll;
      scroll-snap-type: x mandatory;
      flex-wrap: nowrap;

      &__item {
        scroll-snap-align: start;
        scroll-snap-stop: always;
        white-space: nowrap;
      }
    }

    &__local {
      justify-content: start;
      color: $text__secondary-color;
    }

    &__description {
      color: $text__secondary-color;

      &--gallery {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}