.appointment {
  &__primary-field {
    width: 60%;

    &--margin-top {
      margin-top: 20px;
    }
  }

  &__more-info-title {
    margin: 16px 0;
  }

  &__more-info-field {
    margin-top: 10px;
  }

  &__action-buttons {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;

    &__button {
      &:not(:last-child) {
        margin-right: 15px;
      }

      &--left {
        margin-right: auto !important;
      }
    }
  }

  &__alert {
    margin-top: 20px;
  }
}
