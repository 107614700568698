.auto-complete-location {
  &__auto-complete-input {
    background-color: green !important;

    & ::after {
      content: '';
      width: 50px;
      height: 20px;
      background-color: red;
    }
  }

  &__auto-complete-input {
    margin-bottom: 26px;
  }

  &__paper-footer {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;

    img {
      padding: 10px;
    }
  }
}