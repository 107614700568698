.information {
  display: flex;
  
  &:not(:first-child) {
    margin-top: 20px;
  }

  &__icon {
    margin-top: 5px;
    margin-right: 20px;
    width: 28px !important;
  }

  &__body {
    margin-top: 5px;
  }
}