@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.header {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 60px;
  background-color: $app-background-color;
  border-bottom: 1px solid $light-grey;
  z-index: z-index('header');
  transform: translateY(0);
  transition: transform 0.4s ease-out;
  padding-right: 8px;

  &--hidden {
    transform: translateY(-61px);
  }

  &__back {
    margin-right: auto;
    padding: 18px;
    z-index: 1;
  }

  &__logo-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;

    @include media(">tablet") {
      position: relative;
      margin-right: auto;
      margin-left: 25px;
      width: auto;
    }
  }

  &__logo {
    height: 40px;
    display: flex;
    align-items: center;

    img {
      height: 55%;
    }
  }

  &__item {
    margin: 0 10px;

    &--backoffice {
      height: 16px;
    }
  }

  &__current-user {
    &__avatar-wrapper {
      width: 30px;
      height: 30px;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  &__menu-button {
    display: block;
    z-index: z-index('header-menu-button');
    transform: translateY(0);
    transition: all 250ms ease-out;
    margin: 0 10px;

    @include media(">tablet") {
      display: none;
    }

    &--close {
      transform: translateY(30px);
    }
  }
}