@import "styles/theme/colors.scss";

$border-radius: 16px;

.pin-lite {
  position: relative;
  font-size: 16px;
  width: 100%;

  &__wrapper {
    border-radius: $border-radius;

    &--no-image {
      min-height: 270px;
      background-color: lightgray;
      box-shadow: 0px 5px 10px -6px #00000073;
    }
  }

  &__image {
    width: 100%;
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
  }

  &__content {
    background-color: $background__primary-color;
    padding-bottom: 16px;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 28px;
    }

    &__user {
      justify-content: left;
      font-size: 0.8rem;
      font-weight: bold;
      transform: translateY(-20px);
      margin-left: 24px;
    }
  
    &__title {
      color: inherit;
      text-align: left;
      padding: 0;
    }

    &__price {
      font-size: 0.8rem;
      white-space: nowrap;
      margin: 0;
  
      &__value {
        font-size: 1rem; 
      }
    }
  }

  &__skeleton {
    border-radius: 8px;
  }
}
