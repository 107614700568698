@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.premium-plan-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: center;

  &--highlighted {
    transform: scale(1.05);
  }

  &__header {
    display: flex !important;
    align-items: flex-start !important;
    text-align: center;
    background: $primary-color;
    min-height: 56px;

    @include media(">tablet") {
      min-height: 92px;
    }
  }

  &__description {
    margin-top: 12px;
    margin-bottom: 16px;
    padding: 0 32px;
  }

  &__prices {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  &__price {
    margin: 20px 0;
  }

  &__action-wrapper {
    position: relative;
    margin-top: auto;
  }

  &__features {
    padding: 0 32px;

    &__item {
      display: flex;
      justify-content: left;
      align-items: flex-start;
      text-align: left;
      margin-bottom: 8px;

      @include media(">small-tablet") {
        justify-content: center;
      }

      @include media(">tablet") {
        justify-content: left;
      }

      svg {
        width: 16px;
        color: $secondary-color;
        margin-right: 8px;
        height: 18px;
      }
    }
  }

  &__action-button {
    width: 100%;
    height: 42px;

    &__loading {
      margin: 0 !important;
      position: absolute;
      top: calc(50% - 15px);
      left: calc(50% - 15px);
    }
  }
}