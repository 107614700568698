.recovery-modal {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__code-wrapper {
    margin-top: 16px;
  }

  &__code {
    display: flex;
    flex-direction: column;
  }

  &__code-box {
    padding: 16px;
    text-align: center;
    border: 1px solid gray;
    border-radius: 8px;
    margin: 16px 20% 0 20%;
  }

  &__code-actions {
    display: flex;
    justify-content: center;
    gap: 16px;
  }

  &__code-action {
    margin: 0 !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
    
    &:hover {
      background-color: rgba(0, 0, 0, 0.144) !important;
    }
  }

  &__cta {
    display: flex;
    justify-content: flex-end;
  }
}