@import "styles/resources/mixins.scss";

.portfolio-input {
  &__user-pack-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__or {
    text-align: center;
    margin: 27px 0;

    @include media(">tablet") {
      text-align: left;
    }

  }

  &__actions {
    display: flex;
    width: 100%;
    justify-content: space-between;

    margin-top: 30px !important;

    @include media(">tablet") {
      justify-content: flex-end;
    }

    &__button {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}