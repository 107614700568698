@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.home-video {  
  border-radius: 24px;
  width: 95vw;
  max-width: 1900px;
  height: 90vh;
  max-height: calc(95vw * 1.1);
  object-fit: cover;
  background: $background__primary-color;

  @include media(">tablet") {
    height: unset;
    max-height: unset;
    object-fit: unset;
  }
}
