@import "styles/resources/mixins.scss";

.user-info {
  display: flex;
  flex-direction: column;
  position: relative;

  @include media(">tablet") {
    flex-direction: row;
    justify-content: center;
  }

  &__avatar-wrapper {
    display: flex;
    justify-content: center;
    
    @include media(">tablet") {
      display: block;
    }
  }

  &__user-info-edit-btn {
    position: relative;
    margin-right: 16px;
    margin-left: auto;
    margin-top: 15px;

    @include media(">tablet") {
      position: absolute;
      right: 0;
      margin-right: 50px;
    }
  }

  &__content {
    margin: 12px 16px;
    
    @include media(">tablet") {
      margin-left: 50px;
      width: 60%;
    }  
  }

  &__user-info-upload-status {
    position: absolute;
    bottom: 5px;
    right: 50px;
  }

  &__name {
    margin: 0;
    line-height: 1.26em;
    font-size: 28px;
    white-space: pre-wrap;
    font-weight: bold;
    color: #565656;
  }

  &__secondary-info {
    display: flex;
    margin-top: 16px;
    color: grey;
    flex-direction: column;
        
    @include media(">tablet") {
      flex-direction: row;
    } 
  }

  &__external-links-wrapper {
    display: flex;
    margin-top: 16px;
    
    @include media(">tablet") {
      margin-top: 0;
      margin-left: 20px;
    } 
  }

  &__location-wrapper {
    display: flex;
  }

  &__location {
    display: flex;
    align-items: center;
    margin-left: 4px;
  }

  &__external-links {
    display: flex;
    margin: 0;
    margin-left: 8px;
    padding: 0;
    list-style-type: none;

    &__link {
      align-items: center;
      display: flex;

      &:not(:first-child) {
        &::before {
          content: '|';
          margin: 0 15px;
        }
      }
    }
  }

  &__description {
    text-align: justify;
    color: #757575;
    line-height: 24px;
    margin-top: 8px;
    white-space: pre-line;
  }

  &__categories {
    margin-top: 16px;
  }

  &__user-photo-modal {
    &__actions {
      margin-top: 30px !important;
      float: right;
  
      &__button {
        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
  }
}