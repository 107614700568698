.price-label {
  display: flex;
  justify-content: center;
  align-items: center;

  &__price {
    display: flex;
    align-items: flex-end;

    * {
      margin: 0;
    }

    :nth-child(2) {
      margin-bottom: 5px;
    }

    &--without-discount {
      margin-right: 12px;
    }
  }
}
