@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.account-settings {
  display: flex;
  flex-direction: column;

  &__section {
    margin-top: 60px;

    &:first-child {
      margin: 0px;
    }
  }

  &__watermarks {
    position: relative;

    &__empty {
      margin-left: 12px;
      margin-top: 16px;

      &__button {
        margin-top: 12px;
      }
    }

    &__grid {
      margin-top: 12px;
    }

    &__watermark {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      cursor: pointer;
    }

    &__watermark-image {
      border-radius: 16px;
    }

    &__upload-status {
      position: absolute;
      bottom: 28px;
      right: 20px;
    }

    &__loading-wrapper {
      margin: 24px;
    }

    &__add-watermark {
      position: absolute !important;
      top: 0;
      right: 28px;
    }

    &__image-wrapper {
      background: transparent;
      border: 1px solid lightgray;
    }

    &__info {
      display: flex;
      justify-content: space-between;
      padding: 0 8px;
    }
  }

  &__title {
    margin-bottom: 16px;
  }
}
