@import "styles/theme/colors.scss";

.calendar-row {
  border-top: 1px solid rgba(224, 224, 224, 1);
  background-color: white;
  
  &:last-child {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  &__day {
    position: relative;
    height: 100px;
    box-sizing: border-box;
    border-right: 1px solid rgba(224, 224, 224, 1);
    vertical-align: top;
    text-align: center;
    cursor: pointer;

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;

    &:first-child {
      border-left: 1px solid rgba(224, 224, 224, 1);
    }

    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }

    &--adjacent-month {
      color: rgba(0, 0, 0, 0.4);
    }

    &--selected {
      background-color: $background__primary-color;
    }

    &__label {
      height: 1.72em;
      margin-bottom: 2px;

      &--is-today {
        width: 1.72em;
        text-align: center;
        line-height: 1.72;
        margin-left: auto;
        margin-right: auto;
        border-radius: 50%;
        background: $secondary-color;
      }
    }

    &__event {
      position: absolute;
      display: flex;
      align-items: center;
      background: $primary-color;
      width: 97%;
      height: 18px;
      margin: 0 1%;
      border-radius: 3px;
      padding-left: 4px;
      font-size: 12px;
      box-sizing: border-box;

      &:not(:first-child) {
        margin-top: 1px;
      }

      &--shifting-right {
        width: 100%;
        margin-right: 0;
        border-radius: 3px 0 0 3px;
      }

      &--shifting-left {
        width: 99%;
        margin-left: -1%;
        border-radius: 0 3px 3px 0;
      }

      &--shifting {
        width: 101%;
        margin: 0;
        margin-left: -1%;
        border-radius: 0;
      }

      &--second-row {
        top: 46px;
      }

      &--third-row {
        top: 65px;
      }

      &--hidden {
        visibility: hidden;
      }

      &__title {
        margin-left: 5px;
        padding-right: 2px;
      }
    }

    &__more-events {
      position: absolute;
      width: 100%;
      bottom: 0;
    }
  }
}
  